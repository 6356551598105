import React from 'react'
import styles from './Special.module.css'

export const Special = ({background, children, customClasses}) => (
    <div className={`grid full-window-w full-window-h ${styles.special} ${customClasses}`} style={{
        backgroundImage: `url(${background})`
    }}>
        {
            children
        }
    </div>
)