import React from 'react'
import styles from './Video.module.css'
import stylesModal from '../modal/Modal.module.css'
import { Play, Pause, Switch, TextMarks, Text } from '..'
import useAppContext from '../../context/Context'
import useVideoPlayer from '../../hooks/useVideoPlayer'
import '../../styles/animation.css'
import Loader from "react-js-loader";
import {useLocation} from 'react-router-dom'
import { useIntl } from 'react-intl'

import { phrasesByChapter } from '../../utils/handlePhrases'
import URL_MEDIA_BACKEND from '../../constants/media-url'

const CREDIT_TEXTS = [
  ["credits.text.1", "credits.text.2", "credits.text.3", "credits.text.4"],
  ["credits.text.5", "credits.text.6", "credits.text.7"],
  ["credits.text.8", "credits.text.9"],
  ["credits.text.10", "credits.text.11"],
  ["credits.text.12", "credits.text.13"],
  ["credits.text.14", "credits.text.15", "credits.text.16"],
  ["credits.text.17"]
]

export const Video = ({uri, subtitle, customClasses, index, indicator, text, text1, showControls, callback, credits = false}) => {
  const { pathname } = useLocation()
  const chapter = pathname.split("/")[2];

  const intl = useIntl()
  const [creditText, setCreditText] = React.useState([])
    /**
     * Create ref for video
     */
    const videoElement = React.createRef(null);
    /**
     * Handle video loaded
     */
    const [loaded, setLoaded] = React.useState(true)
    /**
     * Style for equals text
     */
    const [stylesInline, setStyleInline] = React.useState({
      opacity: 0,
      animation: 'second 1s linear'
    })

    /**
     * variable for handle pause or play
     */
    const [paused, setPaused] = React.useState(false)

    /**
     * Variables context provider
     */
    const { 
      volumenAudio, 
      showSubtitles, 
      setShowSubtitles, 
      language,
      pauseAllMedia
    } = useAppContext()

    /**
     * Hook for handle video state
     */
    const {
        playerState,
        togglePlay,
        handleOnTimeUpdate,
        toggleMute
    } = useVideoPlayer(videoElement);

    /**
     * When video finish change for another screen
     */
    React.useEffect(() => {  
      if(showControls){
        if( index === indicator){
          let currentDuration = Math.round(( 300 * 100 ) / ( videoElement.current.duration * 1000 )) + 2  
          if(playerState.progress > (100 - currentDuration)){
            callback();
            if (indicator === 42) {
              togglePlay(false);
              setPaused(true);
            }
        }
        }
        
      }
    },[playerState.progress])

    /**
     * Handle autoplay and changes in video state
     */
    const handleVideos = () => {
        if( indicator === index ){
          if( !paused ){
            togglePlay(true)          
          }else{
            togglePlay(false)
          }
        }else{
          togglePlay(false)
          let video = videoElement.current;
          setTimeout(()=>{
            video.currentTime = 0;
          },300)
        }
    }

    React.useEffect(handleVideos,[indicator, playerState.progress])

    React.useEffect(()=>{
      setPaused(false);
    },[indicator])

    /**
     * Hide or show subtitles
     */
    const handleChange = () => {
      setShowSubtitles && setShowSubtitles(!showSubtitles)
    }

  /**
   * Handle video state load
   */
  React.useEffect(()=>{
    if( indicator === index ){
      if( videoElement.current && videoElement.current.readyState >= 3 ){
        setLoaded(false)
      }else{
        setLoaded(true)
      }
    }
    return () => {}
  })

  /**
   * Handle Volumen Changes
   * 
   */
  React.useMemo(()=>{
    volumenAudio > 0 ? toggleMute(false) : toggleMute(true)
  },[volumenAudio])

  /**
   * Method for click event of video controls 
   */
  const toggleControls = () => {
    togglePlay(!playerState.isPlaying)
    setPaused(!paused)
  }

  /**
   * Handle credits
   */
  const handleCredits = () => {
    if( credits ){
      setStyleInline({
        animation: 'second 1s linear',
        opacity: 0
      })
      if(playerState.progress > 3 && playerState.progress <= 11){
        setCreditText(CREDIT_TEXTS[0])
        setStyleInline({
          animation: 'first 1s linear',
          opacity: 1
        })
      }else if( playerState.progress > 12 && playerState.progress <= 21){
        setCreditText(CREDIT_TEXTS[1])
        setStyleInline({
          animation: 'first 1s linear',
          opacity: 1
        })
      }else if( playerState.progress > 22 && playerState.progress <= 29){
        setCreditText(CREDIT_TEXTS[2])
        setStyleInline({
                animation: 'first 1s linear',
                opacity: 1
              })
      }else if( playerState.progress > 30 && playerState.progress <= 39){
        setCreditText(CREDIT_TEXTS[3])
        setStyleInline({
                animation: 'first 1s linear',
                opacity: 1
              })
      }else if( playerState.progress > 40 && playerState.progress <= 72){
        setCreditText(CREDIT_TEXTS[4])
        setStyleInline({
                animation: 'first 1s linear',
                opacity: 1
              })
      }else if( playerState.progress > 73 && playerState.progress <= 84){
        setCreditText(CREDIT_TEXTS[5])
        setStyleInline({
                animation: 'first 1s linear',
                opacity: 1
              })
      }
      else if( playerState.progress > 87 && playerState.progress <= 97){
        setCreditText(CREDIT_TEXTS[6])
        setStyleInline({
                animation: 'first 1s linear',
                opacity: 1
              })
      }
    }
  }

  React.useEffect(handleCredits, [playerState.progress])

  React.useEffect(()=> {
    if (index !== indicator) {
      videoElement.current.pause();
      videoElement.current.removeAttribute("src")
      videoElement.current.load();
    }
  }, [indicator, uri])

  React.useEffect(() => {
    if (pauseAllMedia) {
      togglePlay(false)
      setPaused(true)
    } else {
      togglePlay(true)
      setPaused(false)
    }
  }, [pauseAllMedia])

    return(
        <div className={`grid relative full-w full-h ${styles.video_container} ${customClasses}`} tabIndex={-1}>
          {
            loaded &&
            <>
                <div className={`absolute ${stylesModal.modal}`}>
                  <div style={{
                        position: "absolute",
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%,-50%)'
                  }}>
                    <Loader 
                      type="spinner-default" 
                      bgColor={"#FFFFFF"} 
                      title={""} 
                      color={'#FFFFFF'} 
                      size={100}  
                    />
                  </div>
                </div>
            </>
          }
            <video 
              loop={!showControls} 
              muted={playerState.isMuted} 
              playsInline 
              width="100%" 
              height="100%" 
              ref={videoElement} 
              onTimeUpdate={handleOnTimeUpdate}
              onClick={callback}
            >
                <source src={`${URL_MEDIA_BACKEND}${uri}`} type="video/mp4"></source>
                {
                  showSubtitles &&
                  <track label="English" kind="subtitles" srcLang="en" src={subtitle} default />
                }
            </video>
            {
              showControls &&
              <div className={`absolute cursor-pointer ${styles.controls}`} onClick={toggleControls}>
                  <div className={`grid full-w full-h ${styles.actions}`}>
                      <button>
                      {!playerState.isPlaying ? (
                          <Play />
                      ) : (
                          <Pause />
                      )}
                      </button>
                      <div className={`grid absolute ${styles.progress}`}>
                          <Progress strokeWidth={3} percentage={playerState.progress} />
                      </div>
                  </div>
              </div>
            }
            {
              ( language === 'vl' && showControls ) &&
              <Switch 
                isOn={showSubtitles}
                handleToggle={handleChange}
              />
            }
            {
              text &&
              <TextMarks
                text={text} 
                style={phrasesByChapter(chapter, index, playerState.progress, 0)} 
                chapter={chapter}
              />
            }
            {
              text1 &&
              <TextMarks
                text={text1} 
                style={phrasesByChapter(chapter, index, playerState.progress, 1)} 
                chapter={chapter}
              />
            }
            {
              credits &&
              <div className={`absolute-content flex-column content-center`}>{
                creditText.map(credit => (
                  <Text 
                    className={`${styles.credit_text}`} 
                    dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: credit }) }} 
                    style={stylesInline}
                  />
                ))
              }</div>
            }
        </div>
    )
}

export const Progress = ({ strokeWidth, percentage }) => {
	const radius = (30 - strokeWidth / 2);
    const pathDescription = `
      M 30,30 m 0,-${radius}
      a ${radius},${radius} 0 1 1 0,${2 * radius}
      a ${radius},${radius} 0 1 1 0,-${2 * radius}
    `;

    const diameter = Math.PI * 2 * radius;
    const progressStyle = {
			stroke:"#333",
  		strokeLinecap: 'round',
      strokeDasharray: `${diameter}px ${diameter}px`,
      strokeDashoffset: `${((100 - percentage) / 100 * diameter)}px`,
    };

    return (
      <svg
        className={`absolute ${styles.CircularProgressbar}`}
        viewBox="0 0 60 60"
				width={51}
				height={51}
      >
        <path
          className="CircularProgressbar-trail"
          d={pathDescription}
          strokeWidth={strokeWidth}
          fillOpacity={0}
					style={{
						stroke:"#fff"
					}}
        />
        <path
          className="CircularProgressbar-path"
          d={pathDescription}
          strokeWidth={strokeWidth}
          fillOpacity={0}
          style={progressStyle}
        />
      </svg>
    );
};