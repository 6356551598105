const MESSAGES = {
    32: ['chapter.3.C3_18.text.1','chapter.3.C3_18.text.2'],
    37: [
        'chapter.3.C3_21.text.1',
        'chapter.3.C3_21.text.2',
        'chapter.3.C3_21.text.3',
        'chapter.3.C3_21.text.4',
        'chapter.3.C3_21.text.5',
    ]
}

const MESSAGES_COUNTER = {
    15: ['annexed.3.A3_02.text.1','annexed.3.A3_02.text.2'],
    19: ['annexed.3.A3_04.text.1','annexed.3.A3_04.text.2'],
    24: ['annexed.3.A3_08.text.1','annexed.3.A3_08.text.2', 'annexed.3.A3_08.text.3']
}

const MESSAGES_VIDEOS = {
    8: [
        'chapter.3.C3_07.text'
    ],
    28: [
        'chapter.3.C3_14.text'
    ],
    34: [
        'chapter.3.C3_19.text.1'
    ],
    34_1: [
        'chapter.3.C3_19.text.2'
    ],
    39: [
        'chapter.3.C3_23.text'
    ],
    43: [
        'chapter.3.C3_27.text'
    ],
    44: [
        'chapter.3.C3_28.text'
    ]
}

const MESSAGES_VIDEOS_ANNEXED = {
    21: [
        'annexed.3.A03_06.text'
    ]
}

const SENTENCE = {
    6: {
        text:'chapter.3.C3_05.text'
    },
    40: {
        text: "chapter.3.C3_24.text",
        signature: 'chapter.3.C3_24.author'
    }
}

const TOTAL = 45;

module.exports = {
    TOTAL,
    SENTENCE,
    MESSAGES_VIDEOS,
    MESSAGES_COUNTER,
    MESSAGES,
    MESSAGES_VIDEOS_ANNEXED
}