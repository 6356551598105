import React from "react";
import { useIntl } from "react-intl";

import useAppContext from "../../context/Context";
import { Video, Button, Audio } from "../../components";
import styles from "../home/home.module.css"

const VideoIntro = () => {
    const { language } = useAppContext();
    const intl = useIntl()

    const changeWithClick = () => {
        window.location.href = "/chapters?show=true&chapter=1"
    }

    return(
        <div className="full-window-w full-window-h">
            <Video indicator={1} 
                callback={changeWithClick} 
                showControls={true} 
                index={1} 
                customClasses={`absolute-content content-active`} 
                uri={"/videos/INTRO/INTRO.mp4"} 
                subtitle={
                    language === 'vl' ?
                    '/videos/INTRO_SRT_catalan.vtt' :
                    language === 'cs' ?
                    '/videos/INTRO_SRT_espanol.vtt' :
                    '/videos/INTRO_SRT_ingles.vtt'
                }
            /> 
            <div className={styles.special_footer}>
                <Button onClick={changeWithClick}>{ intl.formatMessage({ id: 'introduction' })}</Button>
            </div>
            <Audio 
                uri=""
                indicator={1}
                elements={[1]}
            /> 
        </div>
    )
}

export default VideoIntro;