import React from 'react'
import '../Instagram/Instagram.css'

export const Facebook = ({type = 'blue'}) =>(
    <svg 
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 8.24 15.88"
        width="15px" height="15px"
    >
        <defs></defs>
        <g id="Capa_2">
            <g id="Capa_1-2">
                <path className={`cls-1-${type}`} d="M6.85,0l.83.06.56.05V2.64H7.83c-.47,0-.94,0-1.41,0a1,1,0,0,0-1,1c0,.69,0,1.39,0,2.12H8.14L7.78,8.64H5.35v7.24H2.44V8.65H0V5.82H2.43v-.2c0-.74,0-1.48,0-2.22A3.55,3.55,0,0,1,3.38,1,3.23,3.23,0,0,1,5.12.1L5.67,0Z"/>
            </g>
        </g>
    </svg>
)