import React, {useCallback, useEffect} from 'react';
import { Navigate, Outlet } from 'react-router-dom';



const RefreshRoute = () => {
  const perf = performance.getEntriesByType('navigation')[0].toJSON();
  const reloadType = perf.type !== 'reload';

  const handler = useCallback((e) => {
    // e.returnValue = '';
    // return true;
  }, []);

  useEffect(() => {
    window.onbeforeunload = handler;

    return () => {
      window.onbeforeunload = handler;
    };
  });
  return reloadType ? <Outlet /> : <Navigate to={"/chapters"} />
};
export default RefreshRoute;