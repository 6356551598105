import React from 'react'
import { Instagram, Facebook } from '..'
import styles from './Social.module.css'
import chapters from '../../constants/chapters'

export const Social = React.memo(({iconType,customClasses}) => (
    <div className={`grid ${styles.container} ${customClasses}`}>
        <a href={chapters.INSTAGRAM_URL}>
            <Instagram type={iconType} />
        </a>
        <a href={chapters.FACEBOOK_URL}>
            <Facebook type={iconType} />
        </a>
    </div>
))