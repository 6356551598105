import React from 'react'
import styles from './SpecialText.module.css'
import { Text, Raya } from '..'

export const SpecialText = ({title,text, ...otherProps}) => (
    <div className={`absolute flex-column ${styles.specialText}`} {...otherProps}>
        {
            title &&
            <div className={`flex-row ${styles.specialText_title}`}>
                <h1 className='flex-row' dangerouslySetInnerHTML={{ __html: title }} />
                <Raya />
            </div>
        }
        <div className={styles.specialText_body}>
            <Text>{ text }</Text>
        </div>
    </div>
)