
const BREAKPOINT_SHOW_PHRASES = {
    1: {
        14: [{
                initial: 23,
                finish: 47
            }],
        17: [
            {
                initial: 12,
                finish: 24
            },
            {
                initial: 85,
                finish: 97
            }
        ],
        30: [{
                initial: 70,
                finish: 90
            }],
        33: [{
            initial: 70,
            finish: 95
        }]
    },
    2: {
        5: [{
            initial: 20,
            finish: 44
        }],
        8: [{
            initial: 12,
            finish: 40
        }],
        15: [{
            initial: 77,
            finish: 90
        }],
        22: [{
            initial: 60,
            finish: 97
        }],
        30: [{
            initial: 24,
            finish: 51
        }]
    },
    3: {
        8: [{
            initial: 50,
            finish: 66
        }],
        21: [{
            initial: 33,
            finish: 90
        }],
        28: [{
            initial: 52,
            finish: 80
        }],
        34: [
            {
                initial: 28,
                finish: 43
            },
            {
                initial: 83,
                finish: 97
            }
        ],
        39: [{
            initial: 75,
            finish: 92
        }],
        43: [{
            initial: 71,
            finish: 87
        }],
        44: [{
            initial: 67,
            finish: 98
        }]
    },
    4: {
        2: [{
            initial: 65,
            finish: 78
        }],
        11: [{
            initial: 66,
            finish: 86
        }],
        19: [{
            initial: 17,
            finish: 38
        }],
        44: [{
            initial: 66,
            finish: 80
        }],
        51: [{
            initial: 41,
            finish: 56
        }],
        54: [{
            initial: 50,
            finish: 78
        }]
    }
}

export const phrasesByChapter = (chapter, indicator, progress, phrase) => {
    let returnStyle = { opacity: 0 };
    if (
        BREAKPOINT_SHOW_PHRASES[chapter] && 
        progress > BREAKPOINT_SHOW_PHRASES[chapter][indicator][phrase].initial &&
        progress < BREAKPOINT_SHOW_PHRASES[chapter][indicator][phrase].finish
    ) {
        returnStyle = {
            animation: 'first 1s linear',
            opacity: 1
        }
    } 
    if (
        BREAKPOINT_SHOW_PHRASES[chapter] && 
        progress > BREAKPOINT_SHOW_PHRASES[chapter][indicator][phrase].finish
    ) {
        returnStyle = phrase === 0 ? { animation: 'second 1s linear', opacity: 0 } : { animation: '', opacity: 0 }
    } 

    return returnStyle;
}