import React from 'react'
import { Know } from '..'
import { useIntl } from 'react-intl'
import styles from './know_more.module.css'

export const KnowMore = React.memo(({customClasses, onClick}) => {
    const intl = useIntl()
    return(
        <div className={`relative ${customClasses} ${styles.animationInOut}`} onClick={onClick}>
            <Know />
            {/* <p className={`absolute ${styles.know_more__text}`}>'{ intl.formatMessage({ id: "know.more"})}'</p> */}
        </div>
    )
})