import React from 'react'

const useVideoPlayer = (videoElement) => {
    const [playerState, setPlayerState] = React.useState({
      isPlaying: false,
      progress: 0,
      speed: 1,
      isMuted: false,
    });
  
    const togglePlay = (data) => {
      setPlayerState({
        ...playerState,
        isPlaying: data
      });
    };
  
    React.useEffect(() => {
      if( playerState.isPlaying ){
        let promise = videoElement.current.play();

        if (promise !== undefined) {
          promise.then(_ => {
            // Automatic playback started!
            // Show playing UI.
          })
          .catch(error => {
            
          });
        }
      }else{
        videoElement.current.pause()
      }
    }, [playerState.isPlaying, videoElement]);
  
    const handleOnTimeUpdate = () => {
      const progress = 
        playerState.progress < (videoElement.current.currentTime / videoElement.current.duration) * 100
        && (videoElement.current.currentTime / videoElement.current.duration) * 100
      setPlayerState({
        ...playerState,
        progress,
      });
    };
  
    const handleVideoProgress = () => {
      const manualChange = 0;
      videoElement.current.currentTime = (videoElement.current.duration / 100) * manualChange;
      setPlayerState({
        ...playerState,
        progress: manualChange,
      });
    };
  
    const toggleMute = (data) => {
      setPlayerState({
        ...playerState,
        isMuted: data
      });
    };
  
    React.useEffect(() => {
      playerState.isMuted
        ? (videoElement.current.muted = true)
        : (videoElement.current.muted = false);
    }, [playerState.isMuted, videoElement]);
  
    return {
      playerState,
      togglePlay,
      handleOnTimeUpdate,
      handleVideoProgress,
      toggleMute,
    };
};

export default useVideoPlayer;