import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import { AppContextProvider } from './context/Context'
import { BrowserRouter } from 'react-router-dom'
import { Layout } from './components'
import { HelmetProvider } from 'react-helmet-async';
import { Routes, Route, Navigate } from 'react-router-dom'

import Start from './modules/start/start';
import VideoIntro from './modules/videoIntro/VideoIntro';
import Animation from './modules/animation/animation';
import Animation2 from './modules/animation2/animation2';
import Animation3 from './modules/animation3/animation3';
import Animation4 from './modules/animation4/animation4';
import Animation5 from './modules/animation5/animation5';
import Chapters from './modules/chapters/chapters';
import RefreshRoute from './RefreshRoute';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HelmetProvider>
      <AppContextProvider>
            <BrowserRouter>
                <Routes>        
                    <Route path="*" element={<Navigate to="/" replace /> }/>
                    <Route path="/" element={<Layout><Start /></Layout>}/>
                    <Route path='/chapters' element={<Layout><Chapters /></Layout>} />
                    <Route exact path='/chapters/1' element={<RefreshRoute />}>
                      <Route exact path="/chapters/1" element={<Layout><Animation /></Layout>}/>
                    </Route>
                    <Route exact path='/chapters/2' element={<RefreshRoute />}>
                      <Route exact path="/chapters/2" element={<Layout><Animation2 /></Layout>}/>
                    </Route>
                    <Route exact path='/chapters/3' element={<RefreshRoute />}>
                      <Route exact path="/chapters/3" element={<Layout><Animation3 /></Layout>}/>
                    </Route>
                    <Route exact path='/chapters/4' element={<RefreshRoute />}>
                      <Route exact path="/chapters/4" element={<Layout><Animation4 /></Layout>}/>
                    </Route>
                    <Route exact path='/chapters/5' element={<RefreshRoute />}>
                      <Route exact path="/chapters/5" element={<Layout><Animation5 /></Layout>}/>
                    </Route>
                    <Route exact path='/video-intro' element={<RefreshRoute />}>
                      <Route exact path="/video-intro" element={<Layout><VideoIntro /></Layout>}/>
                    </Route>
                </Routes>
            </BrowserRouter>
      </AppContextProvider>
  </HelmetProvider>
);
