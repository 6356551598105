import React from 'react'
import { 
    Image, 
    Indicator, 
    DynamicText, 
    Video, 
    Button, 
    SpecialText, 
    Sentence, 
    Audio, 
    Navigate,
    Special,
    KnowMore,
    InitialNavigation
} from '../../components'
import styles from './animation2.module.css'
import useAppContext from '../../context/Context'
import {useIntl} from 'react-intl'
import '../../styles/animation.css'
import { 
    MESSAGES, 
    MESSAGES_KNOW_MORE_1, 
    MESSAGES_KNOW_MORE_3, 
    MESSAGES_VIDEOS, 
    MESSAGES_VIDEOS_KNOW_MORE_1,
    SPECIAL_TEXT,
    SENTENCE,
    SENTENCE_KNOW_MORE_1,
    SPECIAL_TEXT_KNOW_MORE_2,
    TOTAL
} from "../../constants/chapter-2";
import arrow from "../../assets/arrow.svg"

const Animation2 = () => {
    const { 
        indicator, 
        language, 
        handleIndicator, 
        addClasses, 
        showMessage,
        handleNavigate,
        handleFinal,
        resetInit,
        pauseAllMedia,
        setPauseAllMedia
    } = useAppContext()
    const intl = useIntl()
    const [showContinue, setShowContinue] = React.useState(false);

    const [showKnowMore, setShowKnowMore] = React.useState(false);

    React.useEffect(resetInit, []);

    const customDiapositives = {
        refer: React.createRef(null),
        matchesIndicatorsAdd: [2, 10, 20, 21, 22],
        matchesIndicatorsRemove: [3, 11, 21, 22, 23]
    }

    const showNextDiapo = [
        1, 3, 4, 6, 7, 9, 10, 12, 14, 16, 17,
        19, 23, 24, 26, 29, 31, 32, 35, 37, 38, 41, 42, 44, 45
    ];
    
    const handleScroll = (e) => {               
        e.deltaY > 20 && toggleIndicator("add");
        e.deltaY < -20 && toggleIndicator("remove");       
    }

    const pressFunction = (e) => { 
        e.keyCode === 32 && setPauseAllMedia(!pauseAllMedia)
        e.keyCode === 40 && toggleIndicator("add");
        e.keyCode === 38 && toggleIndicator("remove");          
    }

    React.useEffect(() => handleNavigate(showNextDiapo, 2),[indicator])

    React.useEffect(()=>{
        customDiapositives.refer.current.classList.add("first-animation")
    },[])

    const changeWithClick = () => {  
        toggleIndicator("add");
    }

    const makeScrollKnowMore1 = () =>{
        if (indicator === 6 || indicator === 24) {
            setShowKnowMore(true);
            return
        }  
        if (indicator === 35) {
            setShowKnowMore(true);
            return
        }

        if(indicator === 7){
            let timer = setTimeout(()=>{
                toggleIndicator("add");
                return
            },7000);

            return () => {
                clearInterval(timer);
            };
        }
        if( indicator === 11){
            let timer = setTimeout(()=>{
                setShowContinue(true);
                return
            },10000);

            return () => {
                clearInterval(timer);
            };
        }

    }

    React.useEffect(makeScrollKnowMore1,[indicator])

    const toggleIndicator = (operation) => {
        let quantity = 1;
        switch (operation) {
            case "add": (indicator === 6 || indicator === 24) ? (quantity = 6) : indicator === 35 && (quantity = 7);
            break;
            case "remove": (indicator === 12 || indicator === 30) ? (quantity = 6) : indicator === 42 && (quantity = 7);
            break;
            default: {};
        }
        setShowKnowMore(false);
        handleIndicator[operation](customDiapositives, TOTAL, quantity);
    }

    return(        
        <div className={'relative full-window-w full-window-h'} onWheel={handleScroll} onKeyDown={pressFunction} tabIndex={-1}>
            <div ref={customDiapositives.refer} className={`absolute full-w full-h ${styles.transition} ${addClasses ? styles.transitionActive : ''}`} />
            <div className={`relative full-window-w full-window-h grid ${styles.images}`}>
                {/* Content Start */}
                {
                    indicator < 5 &&
                    <>
                        <Image 
                            callback={changeWithClick} 
                            customClasses={`absolute-content ${indicator===1 ? `content-active` : `content-disabled`}`} 
                            uri="/images/CAP_02/C2_00.jpg" 
                        />
                        <Video indicator={indicator}
                            callback={changeWithClick} 
                            showControls={true} 
                            index={2} 
                            customClasses={`absolute-content ${indicator=== 2 ? `content-active` : `content-disabled`}`} 
                            uri={"/videos/VIDEOS_C2/C02_01.mp4"}
                            subtitle={
                                language === 'vl' ?
                                '/videos/VIDEOS_C2/C02_01_SRT_catalan.vtt' :
                                language === 'cs' ?
                                '/videos/VIDEOS_C2/C02_01_SRT_espanol.vtt' :
                                '/videos/VIDEOS_C2/C02_01_SRT_ingles.vtt'
                            }
                        />
                        <Image 
                            callback={changeWithClick} 
                            customClasses={`absolute-content ${indicator===3 || indicator===4 ? ` content-active` : ` content-disabled`}`} 
                            uri="/images/CAP_02/C2_02.jpg" 
                        />
                    </>
                }{
                    indicator === 5 &&
                    <Video indicator={indicator}
                        callback={changeWithClick} 
                        showControls={true} 
                        index={5} 
                        text={MESSAGES_VIDEOS[5]}
                        customClasses={`absolute-content ${indicator=== 5 ? `content-active` : `content-disabled`}`} 
                        uri={"/videos/VIDEOS_C2/C02_03.mp4"} 
                        subtitle={
                            language === 'vl' ?
                            '/videos/VIDEOS_C2/C02_03_SRT_catalan.vtt' :
                            language === 'cs' ?
                            '/videos/VIDEOS_C2/C02_03_SRT_espanol.vtt' :
                            '/videos/VIDEOS_C2/C02_03_SRT_ingles.vtt'
                        }
                    />
                }{
                    (indicator >= 6 && indicator < 13) &&
                    <>                        
                        <Image 
                            callback={changeWithClick} 
                            customClasses={`absolute-content ${indicator===6 ? ` content-active` : ` content-disabled`}`} 
                            uri="/images/CAP_02/C2_04.jpg" 
                        />   

                        {/* Start Know more 1 */}
                        <Image 
                            callback={changeWithClick} 
                            customClasses={`absolute-content ${indicator === 7 ? ` content-active` : ` content-disabled`}`} 
                            uri="/images/CAP_02/C2_04B.jpg" 
                        />
                        <Video indicator={indicator}
                            callback={changeWithClick} 
                            showControls={true} 
                            index={8} 
                            text={MESSAGES_VIDEOS_KNOW_MORE_1[8]}
                            customClasses={`absolute-content ${indicator=== 8 ? `content-active` : `content-disabled`}`} 
                            uri={"/videos/VIDEOS_C2/C02_04C.mp4"}
                            subtitle={
                                language === 'vl' ?
                                '/videos/VIDEOS_C2/C02_04C_SRT_catalan.vtt' :
                                language === 'cs' ?
                                '/videos/VIDEOS_C2/C02_04C_SRT_espanol.vtt' :
                                '/videos/VIDEOS_C2/C02_04C_SRT_ingles.vtt'
                            }
                        />
                        <Image 
                            callback={changeWithClick} 
                            customClasses={`absolute-content ${indicator===9 || indicator===10 ? ` content-active` : ` content-disabled`}`} 
                            uri="/images/CAP_02/C2_05.jpg" 
                        />
                        <Special 
                            customClasses={`absolute-content ${indicator===11 ? `content-active` : `content-disabled`}`} 
                        >
                            <Video indicator={indicator}
                                callback={changeWithClick} 
                                showControls={false} 
                                index={11} 
                                customClasses={`absolute-content ${indicator=== 11 ? `content-active` : `content-disabled`}`} 
                                uri={"/videos/VIDEOS_C2/C02_04D.mp4"}
                            />
                            {
                                showContinue &&
                                <div style={{zIndex: 50, marginTop: 150}}>
                                    <div className={styles.special_footer}>
                                        <Button onClick={changeWithClick}>{ intl.formatMessage({ id: 'animation6.button2' })}</Button>
                                    </div>
                                </div>
                            }
                        </Special>                     
                        {/* End Know more 1 */}              

                        <Image 
                            callback={changeWithClick} 
                            customClasses={`absolute-content  ${indicator===12 ? `content-active` : ` content-disabled`}`} 
                            uri="/images/CAP_02/C2_06.jpg" 
                        />
                    </>
                }{
                    (indicator >= 13 && indicator < 15) &&
                    <>
                        <Video indicator={indicator}
                            callback={changeWithClick} 
                            showControls={true} 
                            index={13} 
                            customClasses={`absolute-content ${indicator=== 13 ? `content-active` : `content-disabled`}`} 
                            uri={"/videos/VIDEOS_C2/C02_07.mp4"}
                            subtitle={
                                language === 'vl' ?
                                '/videos/VIDEOS_C2/C02_07_SRT_catalan.vtt' :
                                language === 'cs' ?
                                '/videos/VIDEOS_C2/C02_07_SRT_espanol.vtt' :
                                '/videos/VIDEOS_C2/C02_07_SRT_ingles.vtt'
                            }
                        />
                        <Image 
                            callback={changeWithClick} 
                            customClasses={`absolute-content ${indicator===14 ? ` content-active` : ` content-disabled`}`} 
                            uri="/images/CAP_02/C2_08.jpg" 
                        />
                    </>
                }{
                    (indicator >= 15 && indicator < 18) &&
                    <>
                        <Video indicator={indicator}
                            callback={changeWithClick} 
                            showControls={true} 
                            index={15} 
                            text={MESSAGES_VIDEOS[15]}
                            customClasses={`absolute-content ${indicator=== 15 ? `content-active` : `content-disabled`}`} 
                            uri={"/videos/VIDEOS_C2/C02_10.mp4"} 
                            subtitle={
                                language === 'vl' ?
                                '/videos/VIDEOS_C2/C02_10_SRT_catalan.vtt' :
                                language === 'cs' ?
                                '/videos/VIDEOS_C2/C02_10_SRT_espanol.vtt' :
                                '/videos/VIDEOS_C2/C02_10_SRT_ingles.vtt'
                            }
                        />
                        <Image 
                            callback={changeWithClick} 
                            customClasses={`absolute-content ${indicator===16 ? ` content-active` : ` content-disabled`}`} 
                            uri="/images/CAP_02/C2_11.jpg" 
                        />
                        <Image 
                            callback={changeWithClick} 
                            customClasses={`absolute-content ${indicator===17 ? ` content-active` : ` content-disabled`}`} 
                            uri="/images/CAP_02/C2_11B.jpg" 
                        />
                    </>
                }{
                    (indicator >= 18 && indicator < 20) &&
                    <>
                        <Video indicator={indicator}
                            callback={changeWithClick} 
                            showControls={true} 
                            index={18} 
                            customClasses={`absolute-content ${indicator=== 18 ? `content-active` : `content-disabled`}`} 
                            uri={"/videos/VIDEOS_C2/C02_12.mp4"} 
                            subtitle={
                                language === 'vl' ?
                                '/videos/VIDEOS_C2/C02_12_SRT_catalan.vtt' :
                                language === 'cs' ?
                                '/videos/VIDEOS_C2/C02_12_SRT_espanol.vtt' :
                                '/videos/VIDEOS_C2/C02_12_SRT_ingles.vtt'
                            }
                        />
                        <Image 
                            callback={changeWithClick} 
                            customClasses={`absolute-content ${indicator===19 ? ` content-active` : ` content-disabled`}`} 
                            uri="/images/CAP_02/C2_13.jpg" 
                        />
                    </>
                }{
                        indicator === 20 &&
                        <Video indicator={indicator}
                            callback={changeWithClick} 
                            showControls={true} 
                            index={20} 
                            customClasses={`absolute-content ${indicator=== 20 ? `content-active` : `content-disabled`}`} 
                            uri={"/videos/VIDEOS_C2/C02_14.mp4"} 
                            subtitle={
                                language === 'vl' ?
                                '/videos/VIDEOS_C2/C02_14_SRT_catalan.vtt' :
                                language === 'cs' ?
                                '/videos/VIDEOS_C2/C02_14_SRT_espanol.vtt' :
                                '/videos/VIDEOS_C2/C02_14_SRT_ingles.vtt'
                            }
                        />
                }{
                    indicator === 21 &&
                    <Video indicator={indicator}
                        callback={changeWithClick} 
                        showControls={true} 
                        index={21} 
                        customClasses={`absolute-content ${indicator=== 21 ? `content-active` : `content-disabled`}`} 
                        uri={"/videos/VIDEOS_C2/C02_15.mp4"} 
                        subtitle={
                            language === 'vl' ?
                            '/videos/VIDEOS_C2/C2_15_SRT_catalan.vtt' :
                            language === 'cs' ?
                            '/videos/VIDEOS_C2/C2_15_SRT_espanol.vtt' :
                            '/videos/VIDEOS_C2/C2_15_SRT_ingles.vtt'
                        }
                    />

                }{
                    (indicator >= 22 && indicator < 25) &&
                    <>
                        <Video indicator={indicator}
                            callback={changeWithClick} 
                            showControls={true} 
                            index={22} 
                            text={MESSAGES_VIDEOS[22]}
                            customClasses={`absolute-content ${indicator=== 22 ? `content-active` : `content-disabled`}`} 
                            uri={"/videos/VIDEOS_C2/C02_16.mp4"}
                            subtitle={
                                language === 'vl' ?
                                '/videos/VIDEOS_C2/C2_16_SRT_catalan.vtt' :
                                language === 'cs' ?
                                '/videos/VIDEOS_C2/C2_16_SRT_espanol.vtt' :
                                '/videos/VIDEOS_C2/C2_16_SRT_ingles.vtt'
                            }
                        />
                        <Image 
                            callback={changeWithClick} 
                            customClasses={`absolute-content ${indicator===23 || indicator===24 ? ` content-active` : ` content-disabled`}`} 
                            uri="/images/CAP_02/C2_17.jpg" 
                        /> 
                    </>

                }{
                    (indicator >= 25 && indicator < 31) &&
                    <>

                        {/* Start Know more 2 */}
                        <Video indicator={indicator}
                            callback={changeWithClick} 
                            showControls={true} 
                            index={25} 
                            customClasses={`absolute-content ${indicator=== 25 ? `content-active` : `content-disabled`}`} 
                            uri={"/videos/VIDEOS_C2/C02_17C.mp4"} 
                            subtitle={
                                language === 'vl' ?
                                '/videos/VIDEOS_C2/C2_17C_SRT_catalan.vtt' :
                                language === 'cs' ?
                                '/videos/VIDEOS_C2/C2_17C_SRT_espanol.vtt' :
                                '/videos/VIDEOS_C2/C2_17C_SRT_ingles.vtt'
                            }
                        />
                        <Image 
                            callback={changeWithClick} 
                            customClasses={`absolute-content ${indicator===26 ? ` content-active` : ` content-disabled`}`} 
                            uri="/images/CAP_02/C2_17D.jpg" 
                        />
                    </>
                }{
                    indicator === 27 &&
                        <Video indicator={indicator}
                            callback={changeWithClick} 
                            showControls={true} 
                            index={27} 
                            customClasses={`absolute-content ${indicator=== 27 ? `content-active` : `content-disabled`}`} 
                            uri={"/videos/VIDEOS_C2/C02_17E.mp4"}
                            subtitle={
                                language === 'vl' ?
                                '/videos/VIDEOS_C2/C2_17E_SRT_catalan.vtt' :
                                language === 'cs' ?
                                '/videos/VIDEOS_C2/C2_17E_SRT_espanol.vtt' :
                                '/videos/VIDEOS_C2/C2_17E_SRT_ingles.vtt'
                            }
                        />
                }{
                    indicator === 28 &&
                        <Video indicator={indicator}
                            callback={changeWithClick} 
                            showControls={true} 
                            index={28} 
                            customClasses={`absolute-content ${indicator=== 28 ? `content-active` : `content-disabled`}`} 
                            uri={"/videos/VIDEOS_C2/C02_17F.mp4"} 
                            subtitle={
                                language === 'vl' ?
                                '/videos/VIDEOS_C2/C2_17F_SRT_catalan.vtt' :
                                language === 'cs' ?
                                '/videos/VIDEOS_C2/C2_17F_SRT_espanol.vtt' :
                                '/videos/VIDEOS_C2/C2_17F_SRT_ingles.vtt'
                            }
                        />  
                }{
                    (indicator >= 29 && indicator < 31) &&    
                    <>              
                        <Special 
                            customClasses={`absolute-content ${indicator=== 29 ? `content-active` : `content-disabled`}`} 
                        >
                            <Image 
                                callback={changeWithClick} 
                                customClasses={`${indicator === 29 ? `animate` : ''} ${indicator=== 29 ? `absolute-content content-active` : `absolute-content content-disabled`}`} 
                                uri="/images/CAP_02/C2_17G.jpg" 
                            />
                            <div style={{zIndex: 50, marginTop: 150}}>
                                <div className={styles.special_footer}>
                                    <Button onClick={changeWithClick}>{ intl.formatMessage({ id: 'animation6.button2' })}</Button>
                                </div>
                            </div>
                        </Special>     
                        {/* End Know more 2 */}                

                        <Video indicator={indicator}
                            callback={changeWithClick} 
                            showControls={true} 
                            index={30} 
                            text={MESSAGES_VIDEOS[30]}
                            customClasses={`absolute-content ${indicator=== 30 ? `content-active` : `content-disabled`}`} 
                            uri={"/videos/VIDEOS_C2/C02_18.mp4"} 
                            subtitle={
                                language === 'vl' ?
                                '/videos/VIDEOS_C2/C2_18_SRT_catalan.vtt' :
                                language === 'cs' ?
                                '/videos/VIDEOS_C2/C2_18_SRT_espanol.vtt' :
                                '/videos/VIDEOS_C2/C2_18_SRT_ingles.vtt'
                            }
                        />
                    </>
                }{
                    (indicator >= 31 && indicator < 35) &&
                    <>
                        <Image 
                            callback={changeWithClick} 
                            customClasses={`absolute-content ${indicator===31 || indicator===32 ? ` content-active` : ` content-disabled`}`} 
                            uri="/images/CAP_02/C2_19.jpg" 
                        />
                        <Video indicator={indicator}
                            callback={changeWithClick} 
                            showControls={true} 
                            index={33} 
                            customClasses={`absolute-content ${indicator=== 33 ? `content-active` : `content-disabled`}`} 
                            uri={"/videos/VIDEOS_C2/C02_20.mp4"} 
                            subtitle={
                                language === 'vl' ?
                                '/videos/VIDEOS_C2/C2_20_SRT_catalan.vtt' :
                                language === 'cs' ?
                                '/videos/VIDEOS_C2/C2_20_SRT_espanol.vtt' :
                                '/videos/VIDEOS_C2/C2_20_SRT_ingles.vtt'
                            }
                        />
                    </>
                }{
                    indicator === 34 &&
                    <Video indicator={indicator}
                        callback={changeWithClick} 
                        showControls={true} 
                        index={34} 
                        customClasses={`absolute-content ${indicator=== 34 ? `content-active` : `content-disabled`}`} 
                        uri={"/videos/VIDEOS_C2/C02_21.mp4"}
                        subtitle={
                            language === 'vl' ?
                            '/videos/VIDEOS_C2/C2_21_SRT_catalan.vtt' :
                            language === 'cs' ?
                            '/videos/VIDEOS_C2/C2_21_SRT_espanol.vtt' :
                            '/videos/VIDEOS_C2/C2_21_SRT_ingles.vtt'
                        }
                    />
                }{
                    indicator === 35 &&
                    <Video indicator={indicator}
                        callback={changeWithClick} 
                        showControls={false} 
                        index={35} 
                        customClasses={`absolute-content ${indicator=== 35 ? `content-active` : `content-disabled`}`} 
                        uri={"/videos/VIDEOS_C2/C02_22.mp4"} 
                    /> 

                }{
                    (indicator >= 36 && indicator < 39) &&
                    <>

                        {/* Start Know more 3 */}
                        <Video indicator={indicator}
                            callback={changeWithClick} 
                            showControls={true} 
                            index={36} 
                            customClasses={`absolute-content ${indicator=== 36 ? `content-active` : `content-disabled`}`} 
                            uri={"/videos/VIDEOS_C2/C02_22B.mp4"} 
                            subtitle={
                                language === 'vl' ?
                                '/videos/VIDEOS_C2/C2_22B_SRT_catalan.vtt' :
                                language === 'cs' ?
                                '/videos/VIDEOS_C2/C2_22B_SRT_espanol.vtt' :
                                '/videos/VIDEOS_C2/C2_22B_SRT_ingles.vtt'
                            }
                        />
                        <Image 
                            callback={changeWithClick} 
                            customClasses={`absolute-content ${indicator===37 || indicator === 38 ? ` content-active` : ` content-disabled`}`} 
                            uri="/images/CAP_02/C2_22C.jpg" 
                        />
                    </>
                }{
                    indicator === 39 &&
                    <Video indicator={indicator}
                        callback={changeWithClick} 
                        showControls={true} 
                        index={39} 
                        customClasses={`absolute-content ${indicator === 39 ? `content-active` : `content-disabled`}`} 
                        uri={"/videos/VIDEOS_C2/C02_22D.mp4"} 
                        subtitle={
                            language === 'vl' ?
                            '/videos/VIDEOS_C2/C2_22D_SRT_catalan.vtt' :
                            language === 'cs' ?
                            '/videos/VIDEOS_C2/C2_22D_SRT_espanol.vtt' :
                            '/videos/VIDEOS_C2/C2_22D_SRT_ingles.vtt'
                        }
                    />
                }{
                    indicator === 40 &&
                    <Video indicator={indicator}
                        callback={changeWithClick} 
                        showControls={true} 
                        index={40} 
                        customClasses={`absolute-content ${indicator=== 40 ? `content-active` : `content-disabled`}`} 
                        uri={"/videos/VIDEOS_C2/C02_22E.mp4"}
                    />
                }{
                    (indicator >= 41 && indicator < 43) &&
                    <>
                        <Special 
                            customClasses={`absolute-content ${indicator===41 ? `content-active` : `content-disabled`}`} 
                        >
                            <Image 
                                callback={changeWithClick} 
                                customClasses={`absolute-content ${indicator===41 ? ` content-active` : ` content-disabled`}`} 
                                uri="/images/CAP_02/C2_22F.jpg" 
                            />
                            <div style={{zIndex: 50, marginTop: 150}}>
                                <div className={styles.special_footer}>
                                    <Button onClick={changeWithClick}>{ intl.formatMessage({ id: 'animation6.button2' })}</Button>
                                </div>
                            </div>
                        </Special>                    
                        {/* End Know more 3 */}             

                        <Video indicator={indicator}
                            callback={changeWithClick} 
                            showControls={false} 
                            index={42} 
                            customClasses={`absolute-content ${indicator=== 42 ? `content-active` : `content-disabled`}`} 
                            uri={"/videos/VIDEOS_C2/C02_09.mp4"}
                        />                
                    </>
                }{
                    indicator >= 43 &&
                    <>

                    <Video indicator={indicator}
                        callback={changeWithClick} 
                        showControls={true} 
                        index={43} 
                        customClasses={`absolute-content ${indicator=== 43 ? `content-active` : `content-disabled`}`} 
                        uri={"/videos/VIDEOS_C2/C02_23.mp4"}
                        subtitle={
                            language === 'vl' ?
                            '/videos/VIDEOS_C2/C2_23_SRT_catalan.vtt' :
                            language === 'cs' ?
                            '/videos/VIDEOS_C2/C2_23_SRT_espanol.vtt' :
                            '/videos/VIDEOS_C2/C2_23_SRT_ingles.vtt'
                        }
                    />
                    <Image 
                        callback={changeWithClick} 
                        customClasses={`absolute-content ${indicator === 44 || indicator === 45 ? ` content-active` : ` content-disabled`}`} 
                        uri="/images/CAP_02/C2_24.jpg" 
                    />
                    <Special customClasses={`absolute-content ${indicator === 46 ? `content-active` : `content-disabled`}`} background="/images/CAP_02/C2_26.jpg">
                        <div>
                            <div className={styles.special_footer}>
                                <Button onClick={()=>handleFinal("/chapters?show=true&chapter=3")}>{ intl.formatMessage({ id: 'button.2.final.chapter.1' })}</Button>
                            </div>
                        </div>
                    </Special>
                    </>
                }                                

                    {/* Indicator */}
                    {
                        (indicator > 6 && indicator < 12) ?
                        <Indicator indicatorsArray={[7, 8, 9, 10, 11]} indicator={indicator} /> :
                        (indicator > 24 && indicator < 30) ?
                        <Indicator indicatorsArray={[25, 26, 27, 28, 29]} indicator={indicator} /> :
                        (indicator > 35 && indicator < 42) ?
                        <Indicator indicatorsArray={[36, 37, 38, 39, 40, 41]} indicator={indicator} /> :
                        <Indicator indicatorsArray={[
                            1, 2, 3, 4, 5, 6, 12, 13, 14, 15, 16,
                            17, 18, 19, 20, 21, 22, 23, 24, 30, 31, 
                            32, 33, 34, 35, 42, 43, 44, 45, 46
                        ]} indicator={indicator} />
                    }
                    {/* Messages */}
                    {
                        MESSAGES[indicator] && MESSAGES[indicator].map((text,i) =>(
                            <DynamicText
                                key={text}
                                customClasses={
                                    `${styles.dynamic_text}
                                    ${ indicator === 16 || indicator === 17 || indicator === 45 ? styles.special_dynamic_text_1 : ''}
                                    ${i === 1 ? styles.special_dynamic_text_2 : ''}
                                    `
                                }
                            >
                                { intl.formatMessage({ id: text}) }
                            </DynamicText>
                        ))
                    }{
                        MESSAGES_KNOW_MORE_1[indicator] && MESSAGES_KNOW_MORE_1[indicator].map((text,i) =>(
                            <DynamicText
                                key={text}
                                customClasses={
                                    `${styles.dynamic_text} 
                                    ${styles.new_color}
                                    `
                                }
                            >
                                { intl.formatMessage({ id: text}) }
                            </DynamicText>
                        )) 
                    }{
                        MESSAGES_KNOW_MORE_3[indicator] && MESSAGES_KNOW_MORE_3[indicator].map((text,i) =>(
                            <DynamicText
                                key={text}
                                customClasses={
                                    `${styles.dynamic_text} 
                                    ${i === 1 ? styles.special_dynamic_text_2 : ''}
                                    ${indicator === 38 ? styles.new_color : ''}
                                    `
                                }
                            >
                                { intl.formatMessage({ id: text}) }
                            </DynamicText>
                        ))                        
                    }{
                        SPECIAL_TEXT[indicator] &&
                        <SpecialText 
                            title={intl.formatMessage({ id: SPECIAL_TEXT[indicator].title })}
                            text={intl.formatMessage({ id: SPECIAL_TEXT[indicator].text })}
                        />
                    }{
                        SPECIAL_TEXT_KNOW_MORE_2[indicator] &&
                        <SpecialText 
                            title={intl.formatMessage({ id: SPECIAL_TEXT_KNOW_MORE_2[indicator].title })}
                            text={intl.formatMessage({ id: SPECIAL_TEXT_KNOW_MORE_2[indicator].text })}
                        />
                    }{
                        SENTENCE[indicator] &&
                        <Sentence 
                            text={intl.formatMessage({ id: SENTENCE[indicator].text })}
                            signature={SENTENCE[indicator].signature ? intl.formatMessage({ id: SENTENCE[indicator].signature })  : ''}
                        />
                    }{
                        SENTENCE_KNOW_MORE_1[indicator] &&
                        <Sentence 
                            text={intl.formatMessage({ id: SENTENCE_KNOW_MORE_1[indicator].text })}
                            signature={SENTENCE_KNOW_MORE_1[indicator].signature ? intl.formatMessage({ id: SENTENCE_KNOW_MORE_1[indicator].signature })  : ''}
                        />
                    }

                    {/* Handle Audios */}
                        <Audio
                            uri={
                                (indicator < 7) ? "/audios/CAP_02/MUS01.mp3" :
                                (indicator >= 7 && indicator < 9) ? "/audios/CAP_02/AMB_01_URBA.mp3" :
                                (indicator >= 9 && indicator < 11) ? "/audios/CAP_02/AMB_02_MAR.mp3" :
                                indicator === 11 ? "" :
                                (indicator >= 12 && indicator < 15) ? "/audios/CAP_02/AMB_03_PASCUA.mp3" :
                                (indicator >= 15 && indicator < 18) ? "/audios/CAP_02/MUS_02_MOD.mp3" :
                                (indicator >= 18 && indicator < 20) ? "/audios/CAP_02/AMB_04_JARDIN.mp3" :
                                (indicator >= 20 && indicator < 23) ? "/audios/CAP_02/AMB_05_CIUDAD.mp3" :
                                (indicator >= 23 && indicator < 25) ? "/audios/CAP_02/MUS_03_MOD.mp3" :
                                (indicator >= 25 && indicator < 27) ? "/audios/CAP_02/AMB_06_NATURA_GENT.mp3" :
                                (indicator >= 27 && indicator < 30) ? "/audios/CAP_02/MUS_03_MOD.mp3" :
                                indicator === 30 ? "" :
                                (indicator >= 31 && indicator < 36) ? "/audios/CAP_02/AMB_08_ESCUELA.mp3" :
                                (indicator >= 36 && indicator < 39) ? "/audios/CAP_02/MUS_04_MOD.mp3" :
                                (indicator >= 39 && indicator < 41) ? "" :
                                (indicator >= 41 && indicator < 43) ? "/audios/CAP_02/AMB_10_CRUZ.mp3" :
                                (indicator >= 43 && indicator < 46) ? "/audios/CAP_02/AMB_05_CIUDAD.mp3" :
                                indicator === 46 && "/audios/CAP_02/MUS_05_MOD.mp3"        
                            }
                            indicator={indicator}
                            elements={[1, 3, 4, 6, 9, 10, 11, 12, 14, 16, 17, 19, 23, 24, 26, 29, 31, 32, 35, 37, 38, 40, 41, 42, 44, 45, 46]}
                        />
                    {
                        showKnowMore &&
                        <KnowMore customClasses={`
                            ${styles.know_more}
                            ${ indicator === 24 ? styles.change_color_know_more : ''}
                        `} onClick={() => {
                            handleIndicator.add(customDiapositives, TOTAL);
                            setShowKnowMore(false);
                        }} />
                    }

                    {/* Show Navigator */}
                    {
                        indicator > 1 ?
                        <>{
                            showMessage && 
                            <Navigate 
                                indicator={indicator} 
                                handleClick={changeWithClick}
                            />
                        }</>
                        :   <InitialNavigation 
                                showArrow={showMessage}
                                handleClick={changeWithClick}
                            />
                    }
                    {/* Logo Fadrines */}
                    <a className={styles.logo} href="/">
                        <img src="/images/logo.svg" alt="Logo" />
                    </a>
            </div>
        </div>
    )
}

export default Animation2