const MESSAGES = {
    3: ['animation.text1'], 
    10: ['animation.text4'],
    14: ['dynamic_text_1'],
    16: ['dynamic_text_2'],
    26: ['animation.text19']
}

const MESSAGES_VIDEOS = {
    4: [
        'animation.text2.sub1'
    ],
    17: [
        'dynamic_text_3'
    ],
    17.5: [
        'dynamic_text_3_5'
    ],
    30: [
        'dynamic_text_5'
    ],
    33: [
        'dynamic_text_7'
    ]
}

const SPECIAL_TEXT = {
    13: {
        title: 'animation.text5.title',
        text: 'animation.text5.text',
    },
    20: {
        title: 'animation.text18.title',
        text: 'animation.text18.text',
    }
}

const SENTENCE = {
    18: {
        text:'sentence.text',
        signature:'sentence.author'
    },
    28: {
        text:'sentence_2.text',
        signature:'sentence_2.author'
    },
    35: {
        text:'sentence_3.text',
        signature:'sentence_3.author'
    }
}

const TOTAL = 37;

module.exports = {
    MESSAGES,
    MESSAGES_VIDEOS,
    SPECIAL_TEXT,
    SENTENCE,
    TOTAL
}