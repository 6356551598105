import React from 'react'
import styles from './TextMarks.module.css'
import { Marks } from '..'
import { useIntl } from 'react-intl'

export const TextMarks = React.memo(({text, chapter,...otherProps}) => {
    const intl = useIntl()
    return(
        <div className={`flex-row absolute ${styles.text_mark}`} {...otherProps}>
            <Marks chapter={chapter} />
            <p dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: text })}} />
        </div>
    )
})