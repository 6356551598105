import React from 'react'
import styles from './Navigate.module.css'
import { useIntl } from 'react-intl'
import arrow from '../../assets/arrow.svg'

export const InitialNavigation = React.memo(({showArrow, handleClick}) => {
    const intl = useIntl()
    return(
        <div 
            className={`flex-column content-center align-center z-index-50 indicator-move-on ${styles.navigate}`} 
            onClick={handleClick}
        >
            <p dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'start.footer' }) }} />
            {
                showArrow &&
                <img className={styles.arrow} src={arrow} />
            }
        </div>
    )
})