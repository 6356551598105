import React from 'react'
import ReactAudioPlayer from 'react-audio-player'
import useAppContext from '../../context/Context'
import { Volume } from '..'
import styles from './Audio.module.css'

export const Audio = ({uri, indicator, elements }) =>{
    const { volumenAudio, setVolumenAudio, pauseAllMedia } = useAppContext()

    React.useEffect(() => {
        if( volumenAudio > 0 ){
            if (elements.find(element => element === indicator)) {
                setVolumenAudio(100)
            }else{
                setVolumenAudio(25)
            }
        }

    },[indicator])

    React.useEffect(() => {
        const audioTag = document.querySelector("#audio-id-component");
        if (pauseAllMedia) {
            audioTag.pause();
        } else {
            audioTag.play();
        }
    },[pauseAllMedia])

    return(
        <>
            <ReactAudioPlayer
                id="audio-id-component"
                src={uri}
                autoPlay={true}
                controls
                volume={volumenAudio/100}
                style={{ display: 'none'}}
                loop={true}
            />
            <HandleVolume />
        </>
    )
}

const HandleVolume = () =>{
    const { setVolumenAudio } = useAppContext()
    const [show, setShow] = React.useState(true)

    const handleVolumen = () =>{        
        setShow(!show)
    }

    React.useEffect(() => {
        if(show){
            setVolumenAudio && setVolumenAudio(100)
        }else setVolumenAudio && setVolumenAudio(0)
    },[show])

    return(
        <div className={`flex-column absolute ${styles.audio_controller}`}>
            <div onClick={handleVolumen} className={styles.indicator}>
                <Volume 
                    volume={show}
                />
            </div>
        </div>
    )
}