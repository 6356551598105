import React from 'react'
import styles from './Accordion.module.css'
import { Plus } from '..'
import { Number } from '..'
import { useIntl } from "react-intl"
import chapters from '../../constants/chapters'
import useAppContext from '../../context/Context'
import {useNavigate, useLocation } from 'react-router-dom'

export const Accordion = () =>{
    const { accordionActive, setAccordionActive } = useAppContext()
    const intl = useIntl()
    const { ACCORDION } = chapters;
    const location = useLocation()

    const handleLocation = () => {
        if( location.search !== ''){
            let chapter = location.search.split("chapter=")[1];
            chapter && setAccordionActive(chapter)
        }
    }

    React.useEffect(handleLocation,[])

    return (
        <div className={`flex-row full-window-h ${styles.accordion}`}>   
                {
                    ACCORDION.map((item,i) =>(
                        <Item 
                            key={i}
                            text={ intl.formatMessage({ id: item.text })} 
                            number={item.number} 
                            callback={setAccordionActive}
                            accordionActive={accordionActive}
                            image={item.image}
                        />
                    ))
                }
        </div>
    )
}

const Item = ({number, text, callback, accordionActive, image}) => {
    const navigate = useNavigate()
    const intl = useIntl()
    const [disabledHover, setDisabledHover] = React.useState(true)
    const handleHover = (data) => {
        if( !disabledHover ){
            callback(data)
        }
    }

    React.useEffect(()=>{
        setTimeout(()=>{
            setDisabledHover(false)
        },500)
    },[])

    const handleClick = () => {
        if (number === 0) {
            window.location.href = "/video-intro";
            return;
        }

        window.location.href = `/chapters/${number}`;
    }

    return (
        <div 
            className={`grid relative full-window-h ${styles.item} ${number == accordionActive ? styles.item_hover : ''}`} 
            onClick={handleClick}
            onMouseOver={()=>handleHover(number)}
            style={{
                boxShadow: number === 0 ? 'none' : ''
            }}
        >
            <div className={`grid ${styles.container_indicator}`}>
                <div className={styles.text}>
                    {
                        (number !== 0 && number !== 5) &&
                        <p>{ intl.formatMessage({ id: `chapter.${number}.text`}) }</p>
                    }
                </div>
                <div className={`flex-column ${styles.indicator}`}>
                    <a><Plus /></a>
                    <h3>{ text }</h3>
                </div>
            </div>
            <Number customClasses={`absolute ${styles.numbers}`} number={number} />
            <div className={`absolute full-window-h ${styles.image}`}>
                <img className='full-w full-h' src={image} id="image-chapter" alt='Chapter background' />
                {
                    accordionActive === number &&
                    <div className={`absolute ${styles.chapters_title}`}  style={{
                        top: accordionActive === 1 ? '12%' : '',
                        left: accordionActive === 1 ? '50%' : accordionActive === 5 ? '57%' : '',
                     }}>
                        <span className='block'>{number}.</span>
                        { text }
                    </div>
                }
            </div>
        </div>  
    )
}