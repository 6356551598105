import React from 'react'
import styles from './Switch.module.css'

export const Switch = React.memo(({ isOn, handleToggle, customStyle }) => {
  return (
    <div className={`flex-row absolute align-center ${styles.container}`} style={customStyle}>
      <span>CC</span>
      <input
        checked={isOn}
        onChange={handleToggle}
        className={styles.react_switch_checkbox}
        id={`react-switch-new`}
        type="checkbox"
      />
      <label
        className={`flex align-center cursor-pointer relative ${styles.react_switch_label}`}
        htmlFor={`react-switch-new`}
        style={{
          backgroundColor: !isOn ? 'transparent' : ''
        }}
      >
        <span 
          className={`absolute cursor-pointer ${styles.react_switch_button}`} 
          style={{
            backgroundColor: !isOn ? '#fff' : ''
          }}
        />
      </label>
    </div>
  );
});