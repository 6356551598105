const MESSAGES = {
    4: ['chapter.4.C4_02.text.1'],
    5: ['chapter.4.C4_02.text.1','chapter.4.C4_02.text.2'],
    8: ['chapter.4.C4_04.text.1', 'chapter.4.C4_04.text.2'],
    13: ['chapter.4.C4_08.text'],
    47: ['chapter.4.C4_21.text']
}

const MESSAGES_ANNEXED = {
    29: ['annexed.4.A_02_01.text'],
    30: ['annexed.4.A_02_01.B.text'],
    31: ['annexed.4.A_02_01.C.1.text', 'annexed.4.A_02_01.C.2.text', 'annexed.4.A_02_01.C.3.text'],
    34: ['annexed.4.A_02_03.1.text', 'annexed.4.A_02_03.2.text'],
    38: ['annexed.4.A_02_06.1.text', 'annexed.4.A_02_06.2.text'],
    41: ['annexed.4.A_02_08.1.text', 'annexed.4.A_02_08.2.text'],
}

const MESSAGES_VIDEOS = {
    2: [
        'chapter.4.C4_01.text'
    ],
    11: [
        'chapter.4.C4_07.text'
    ],
    19: [
        'chapter.4.C4_14.text'
    ],
    44: [
        'chapter.4.C4_19.text'
    ],
    51: [
        'chapter.4.C4_25.text'
    ],
    54: [
        'chapter.4.C4_26.text'
    ]
}

const SENTENCE = {
    17: {
        text:'chapter.4.C4_12.text',
        signature: 'chapter.4.C4_12.author'
    },
    27: {
        text: "chapter.4.C4_17.text",
        signature: 'chapter.4.C4_17.author'
    }
}

const SPECIAL_TEXT = {
    20: {
        title: 'chapter.4.C4_15.title',
        text: 'chapter.4.C4_15.text',
    },
}

const SPECIAL_TEXT_KNOW_MORE = {
    21: [{ text: 'chapter.4.C4_15.B.text' }],
    22: [{ text: 'chapter.4.C4_15.B.text' }, { text: 'chapter.4.C4_15.C.text' }],
    23: [{ text: 'chapter.4.C4_15.B.text' }, { text: 'chapter.4.C4_15.C.text' }, { text: 'chapter.4.C4_15.D.text' }],
    24: [{ text: 'chapter.4.C4_15.B.text' }, { text: 'chapter.4.C4_15.C.text' }, { text: 'chapter.4.C4_15.D.text' }, { text: 'chapter.4.C4_15.E.text' }],
    25: [{ text: 'chapter.4.C4_15.B.text' }, { text: 'chapter.4.C4_15.C.text' }, { text: 'chapter.4.C4_15.D.text' }, { text: 'chapter.4.C4_15.E.text' }, { text: 'chapter.4.C4_15.F.text' }],
}

const TOTAL = 56;

module.exports = {
    TOTAL,
    SPECIAL_TEXT_KNOW_MORE,
    SPECIAL_TEXT,
    SENTENCE,
    MESSAGES_VIDEOS,
    MESSAGES_ANNEXED,
    MESSAGES
}