import React from 'react'
import {Helmet} from "react-helmet-async"

export const Head = React.memo(({content}) => {
    return(
        <Helmet>
            <title>Fadrines</title>
            <link rel="icon" href="/images/logo-blue.svg" />
            <link rel="apple-touch-icon" href="/images/logo-blue.svg" />
            <meta
                name="description"
                content={content}
            />
            <meta name="google" content="notranslate" />
        </Helmet>
    )
})