import React from 'react'
import styles from './start.module.css'
import {Text, Button} from '../../components'
import {useIntl} from 'react-intl'
import { useNavigate } from 'react-router-dom'
import useAppContext from '../../context/Context'
import '../../styles/animation.css'

const Start = () => {
    const intl = useIntl()
    const [selected,setSelected] = React.useState('Valencià')
    const history = useNavigate()
    const LANGS = ['Valencià', 'Castellano', 'English']
    const { setLanguage } = useAppContext()

    const handleLanguage = data => {
        switch(data){
            case 'Valencià': { 
                setLanguage && setLanguage('vl')
                setSelected('Valencià') 
            }
            break
            case 'English': { 
                setLanguage && setLanguage('en')
                setSelected('English') 
            }
            break
            default: { 
                setLanguage && setLanguage('cs')
                setSelected('Castellano') 
            }
            break
        }
    }

    return(
            <div className={styles.container}>  
                <div className={styles.container_body}>
                    <div className={styles.first_child}>
                        <Text className={styles.body_title}>Fadrines.</Text>
                        <Text className={styles.body_text}>{ intl.formatMessage({ id: 'start.text'})}</Text>
                    </div>
                    <div className={`flex-row full-w align-center content-center ${styles.buttons}`}>
                        {
                            LANGS.map(item =>(
                                <Button customClasses={
                                    selected === item ? styles.button_selected : ''
                                } key={item} onClick={()=>handleLanguage(item)}>{item}</Button>
                            ))
                        }
                    </div>
                    <div className={styles.footer}>
                        <div onClick={() => history("/video-intro")}>
                            <span>{ intl.formatMessage({ id: 'home.start'})}</span>
                            <div className={styles.arrow} />
                        </div>
                    </div>
                </div>
                <div className={`flex-row absolute ${styles.icons}`}>
                    <img src='/images/letno.png' alt='Letno Logo' />
                    <img src='/images/dipu_valencia.png' alt='Dipu Valencia Logo' />
                </div>
            </div>
    )
}

export default Start