const MESSAGES = {
    4: ['chapter.2.C2_02B.text'],    
    16: ['chapter.2.C2_11.text'],
    17: ['chapter.2.C2_11B.text'],
    24: ['chapter.2.C2_17B.text'],
    32: ['chapter.2.C2_19B.text'],
    45: ['chapter.2.C2_24.text', 'chapter.2.C2_24.text.1']
}

const MESSAGES_KNOW_MORE_1 = {
    10: ['chapter.2.C2_05B.text'],
}

const MESSAGES_KNOW_MORE_3 = {
    38: ['chapter.2.C2_22C.text']
}

const MESSAGES_VIDEOS = {
    5: [
        'chapter.2.C2_03.text'
    ],
    15: [
        'chapter.2.C2_10.text'
    ],
    22: [
        'chapter.2.C2_16.text'
    ],
    30: [
        'chapter.2.C2_18.text'
    ]
}

const MESSAGES_VIDEOS_KNOW_MORE_1 = {
    8: ['chapter.2.C2_04C.text']
}

const SPECIAL_TEXT = {
    6: {
        title: 'chapter.2.C2_04.title',
        text: 'chapter.2.C2_04.text',
    },
    19: {
        title: 'chapter.2.C2_13.title',
        text: 'chapter.2.C2_13.text',
    }
}

const SPECIAL_TEXT_KNOW_MORE_2 = {
    26: {
        title: 'chapter.2.C2_17D.title',
        text: 'chapter.2.C2_17D.text',
    }
}

const SENTENCE = {
    35: {
        text: "chapter.2.C2_22.text",
        signature: 'chapter.2.C2_22.author'
    },
    42: {
        text:'chapter.2.C2_09.text'
    }
}

const SENTENCE_KNOW_MORE_1 = {
    11: {
        text: 'chapter.2.C2_04D.text'
    }
}

const TOTAL = 46;

module.exports = {
    MESSAGES,
    MESSAGES_KNOW_MORE_1,
    MESSAGES_KNOW_MORE_3,
    MESSAGES_VIDEOS,
    MESSAGES_VIDEOS_KNOW_MORE_1,
    SPECIAL_TEXT,
    SPECIAL_TEXT_KNOW_MORE_2,
    SENTENCE,
    SENTENCE_KNOW_MORE_1,
    TOTAL
}