import React from 'react'

export const Plus = () => (
    <svg width="60px" height="60px" viewBox="0 0 60 60" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Artboard" transform="translate(-1574.000000, -117.000000)" fill="#0617FF" fillRule="nonzero">
                <g id="Group-18" transform="translate(1574.000000, 117.000000)">
                    <polygon id="icono-mas" points="30.1352 22 30.135 29.134 37.27 29.1348 37.27 30.1348 30.135 30.134 30.1352 37.27 29.1352 37.27 29.135 30.134 22 30.1348 22 29.1348 29.135 29.134 29.1352 22"></polygon>
                </g>
            </g>
        </g>
    </svg>
)