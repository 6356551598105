import React from 'react'
import styles from './Modal.module.css'
import { Close } from '..'

export const Modal = ({title, text, callback}) => {
    return(
        <>
            <div className={`fixed absolute ${styles.modal}`} onClick={callback}>
                <div onClick={callback}><Close /></div>
            </div>
            <div className={`absolute ${styles.children}`}>
                <div className={styles.credits}>
                    <h3>{ title }</h3>
                    <p dangerouslySetInnerHTML={{ __html: text}} />
                </div>
            </div>
        </>
    )
}