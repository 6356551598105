import React from 'react'

export const Image = ({uri, customClasses,callback, ...otherProps}) => {
       const [activeAnimation, setActivateAnimation] = React.useState(true)
       React.useEffect(()=> {
              setTimeout(()=>{
                     setActivateAnimation(customClasses.includes('content-active'))
              },300)
       },[customClasses])

       return <div 
              onClick={callback} 
              className={`${customClasses} `}
       >
              <img 
                     className={`full-w full-h ${ activeAnimation && 'animate'}`} 
                     src={uri} 
                     loading="lazy"
                     {...otherProps} 
                     alt="Imagen"
              />
       </div>
}