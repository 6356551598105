import React from 'react'
import { Head, Expand, Menu, Language } from '..'
import styles from './Layout.module.css'
import { IntlProvider } from 'react-intl';
import en from '../../lang/en.json'
import cs from '../../lang/es.json'
import vl from '../../lang/vl.json'
import useAppContext from '../../context/Context'
import { useLocation } from 'react-router-dom'
import Static from '../../modules/static/static'
import '../../styles/global.css'

export const Layout = ({children}) => {
    const { language, handle, FullScreen } = useAppContext()
    const location = useLocation()
    const messages = {
        en: en,
        cs: cs,
        vl: vl
      }
    
    const getLanguage = (locale) => {
        const dash_index = locale.indexOf('-')
        if (dash_index >= 0)
        {
            return locale.substring(0, dash_index)
        }
        return locale
    }


    return(
        <IntlProvider locale={getLanguage(language)} defaultLocale="es" messages={messages[getLanguage(language)]}>
                <FullScreen className={`relative full-w full-h`} handle={handle}>
                    <Head content="Fadrines" />
                    <>
                        {
                            
                            <div className={`absolute cursor-pointer z-index-50 ${styles.expand}`} onClick={handle.enter} style={{
                                top: (location.pathname !== '/') && location.pathname !== '/chapters' ? 40 : '',
                                right: (location.pathname !== '/') && location.pathname !== '/chapters' ? 15 : '',                            
                            }}>
                                <Expand 
                                    type={
                                        (location.pathname === '/chapters' || location.pathname === "/")
                                        ? 'blue'
                                        : 'white'
                                    }
                                    customText={(location.pathname !== '/') && location.pathname !== '/chapters' ? styles.custom_text : ''}
                                    style={{
                                        flexDirection: (location.pathname !== '/') && location.pathname !== '/chapters' ? 'row-reverse' : ''
                                    }}
                                />
                            </div>
                        }
                        <Menu 
                            showSocial={location.pathname !== '/' && location.pathname !== '/chapters'} 
                            customClasses={`absolute z-index-50 ${styles.menu}`} 
                        />
                    {
                        <Static />
                    }
                    {
                        children
                    }                    
                    </>
                </FullScreen>
        </IntlProvider>
    )
}