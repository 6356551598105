import React from 'react'
import './Marks.css'

export const Marks = ({ chapter }) => (
    <svg 
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 75.77 67.24"
        width="45px"
        height="45px"
    >
        <defs></defs>
        <g id="Capa_2">
            <g id="Capa_1-2">
                <path className={`
                ${
                    chapter == 1 ? 'cls-pink' :
                    chapter == 2 ? 'cls-purple' : 
                    chapter == 3 ? 'cls-yellow' :
                    chapter == 4 ? 'cls-orange' :
                    'cls-white'
                }`} d="M29.7,67.24H0v-30Q0,19.8,7.34,9.9T30,0c0,1.14-.06,2.73-.17,4.78s-.17,3.64-.17,4.78A15.25,15.25,0,0,0,21,12.46a15.35,15.35,0,0,0-4.78,6.31,22.5,22.5,0,0,0-1.53,8.54q0,4.77.34,9.89H29.7Zm45.73,0H46.08v-30q0-17.4,7.34-27.3T75.77,0V9.56a15.24,15.24,0,0,0-8.7,2.9,15.27,15.27,0,0,0-4.78,6.31,22.5,22.5,0,0,0-1.53,8.54q0,4.77.34,9.89H75.43Z"/>
            </g>
        </g>
    </svg>
)