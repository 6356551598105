import React from 'react'
import styles from './Menu.module.css'
import { useNavigate, useLocation } from 'react-router-dom'
import { Social, Raya } from '..'
import { useIntl } from 'react-intl'
import useAppContext from '../../context/Context'

const CHAPTERS_TITLE = {
    1: "accordion.item1",
    2: "accordion.item2",
    3: "accordion.item3",
    4: "accordion.item4",
    5: "accordion.item5"
}

export const Menu = React.memo(({showSocial, customClasses}) => {
    const intl = useIntl()
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const { setIndicator } = useAppContext()
    let chapter = pathname.split('/')[2]
    const handleNavigate = (link) =>{
        navigate(link)
        setIndicator(1)
    }

    return(
        <div className={`flex-column ${styles.menu} ${customClasses}`}>
            <div className={`cursor-pointer flex-column align-center content-center ${styles.menu_item}`} onClick={()=>handleNavigate("/")}>
                <Item changeColor={showSocial} />
                <MenuItemContent 
                    changeColor={showSocial}
                    name={ intl.formatMessage({ id: "menu.start"}) }
                />
            </div>
            <div className={`cursor-pointer flex-column align-center content-center ${styles.menu_item}`} onClick={()=>handleNavigate("/chapters?show=true")}>
                <Item changeColor={showSocial} />
                <MenuItemContent 
                    changeColor={showSocial}
                    name={ intl.formatMessage({ id: "menu.chapters"}) }
                />
            </div>
            <div className={`cursor-pointer flex-column align-center content-center ${styles.menu_item}`} onClick={()=>handleNavigate("/")}>
                <Item changeColor={showSocial} />
                <MenuItemContent 
                    changeColor={showSocial}
                    name={ intl.formatMessage({ id: "menu.blog"}) }
                />
            </div>            
            {
                showSocial &&
                <div className={`cursor-pointer flex-column align-center content-center ${styles.menu_item}`}>
                    <Item changeColor={showSocial} />
                    <Social customClasses={`relative ${styles.social}`}
                        iconType={
                            showSocial ? 'white' : 'blue'
                        }
                    />
                </div>
            }
            {
                ( showSocial && chapter ) &&
                <div className={`flex-row align-center ${styles.detail}`}>
                    <span>{ intl.formatMessage({ id: CHAPTERS_TITLE[chapter] }) }</span>
                    <Raya />
                </div>
            }
        </div>
    )
})

const Item = ({changeColor}) => (
    <div className={styles.item} style={{
        backgroundColor: changeColor ? '#fff' : ''
    }} />
)

const MenuItemContent = ({name, changeColor}) => (
    <div className={styles.menu_item_content} style={{
        color: changeColor ? '#fff' : ''
    }}>
        {name}
    </div>
)