import React from "react";
import { 
    Image, 
    Indicator, 
    DynamicText, 
    Video, 
    Button, 
    InitialNavigation, 
    SpecialText, 
    Sentence, 
    Audio, 
    Navigate,
    Special,
    KnowMore
} from '../../components'
import useAppContext from '../../context/Context'
import { useIntl } from 'react-intl'

import styles from '../animation2/animation2.module.css'
import css from './animation4.module.css'
import { 
    TOTAL, 
    MESSAGES, 
    MESSAGES_ANNEXED, 
    MESSAGES_VIDEOS, 
    SENTENCE, 
    SPECIAL_TEXT, 
    SPECIAL_TEXT_KNOW_MORE 
} from "../../constants/chapter-4";

const Animation4 = () =>{
    const { 
        indicator, 
        language, 
        handleIndicator, 
        addClasses, 
        showMessage,
        handleNavigate,
        handleFinal,
        resetInit,
        pauseAllMedia,
        setPauseAllMedia
    } = useAppContext()
    const intl = useIntl()
    const [showKnowMore, setShowKnowMore] = React.useState(false)

    React.useEffect(resetInit, []);

    const customDiapositives = {
        refer: React.createRef(null),
        matchesIndicatorsAdd: [2, 6, 11, 45],
        matchesIndicatorsRemove: [3, 7, 12, 46]
    }

    const showNextDiapo = [
        1, 3, 4, 5, 7, 8, 9, 12, 13, 17, 20, 21, 22, 23, 24, 25, 27,
        29, 30, 31, 33, 34, 37, 38, 40, 41, 43,
        46, 47, 48, 52
    ];

    const handleScroll = (e) => {       
        e.deltaY > 20 && toggleIndicator("add");
        e.deltaY < -20 && toggleIndicator("remove");      
    }

    const pressFunction = (e) => { 
        e.keyCode === 32 && setPauseAllMedia(!pauseAllMedia)
        e.keyCode === 40 && toggleIndicator("add");
        e.keyCode === 38 && toggleIndicator("remove"); 
    }

    const changeWithClick = () => {  
        toggleIndicator("add");
    }

    const toggleShowAdditionalElements = () => {
        if(indicator === 20) {
            setShowKnowMore(true)
        }else{
            setShowKnowMore(false)
        }      
    }

    React.useEffect(toggleShowAdditionalElements,[indicator])
    React.useEffect(() => handleNavigate(showNextDiapo, 4),[indicator])

    const handleShowKnowMore = () => {
        handleIndicator.add(customDiapositives, TOTAL, 1);
        setShowKnowMore(false);
    }

    React.useEffect(()=>{
        customDiapositives.refer.current.classList.add("first-animation")
    },[])

    const toggleIndicator = (operation) => {
        let quantity = 1;
        switch (operation) {
            case "add": indicator === 20 ? (quantity = 6) : indicator === 28 && (quantity = 16);
            break;
            case "remove": indicator === 26 ? (quantity = 6) : indicator === 44 && (quantity = 16);
            break;
            default: {};
        }

        handleIndicator[operation](customDiapositives, TOTAL, quantity);
    }

    return(
        <div className={'relative full-window-w full-window-h'} onWheel={handleScroll} onKeyDown={pressFunction} tabIndex={-1}>
            <div ref={customDiapositives.refer} className={`absolute full-w full-h ${styles.transition} ${addClasses ? styles.transitionActive : ''}`} />
            <div className={`relative full-window-w full-window-h grid ${styles.images}`}>
                {/* Content Start */}
                {
                    indicator < 6 &&
                    <>                        
                        <Image 
                            callback={changeWithClick} 
                            customClasses={`absolute-content ${indicator===1 ? ` content-active` : ` content-disabled`}`} 
                            uri="/images/CAP_04/C4_00.jpg" 
                        />
                        <Video indicator={indicator}
                            callback={changeWithClick} 
                            showControls={true} 
                            index={2} 
                            text={MESSAGES_VIDEOS[2]}
                            customClasses={`absolute-content ${indicator=== 2 ? `content-active` : `content-disabled`}`} 
                            uri={"/videos/VIDEOS_C4/C4_01.mp4"} 
                            subtitle={
                                language === 'vl' ?
                                '/videos/VIDEOS_C4/C4_01_SRT_catalan.vtt' :
                                language === 'cs' ?
                                '/videos/VIDEOS_C4/C4_01_SRT_espanol.vtt' :
                                '/videos/VIDEOS_C4/C4_01_SRT_ingles.vtt'
                            }
                        />
                        <Image 
                            callback={changeWithClick} 
                            customClasses={`absolute-content ${indicator===3 || indicator === 4 || indicator === 5 ? ` content-active` : ` content-disabled`}`} 
                            uri="/images/CAP_04/C4_02.jpg" 
                        />
                    </>
                }{
                    (indicator >= 6 && indicator < 9) &&
                    <>
                        <Video indicator={indicator}
                            callback={changeWithClick} 
                            showControls={true} 
                            index={6} 
                            customClasses={`absolute-content ${indicator=== 6 ? `content-active` : `content-disabled`}`} 
                            uri={"/videos/VIDEOS_C4/C4_03.mp4"} 
                            subtitle={
                                language === 'vl' ?
                                '/videos/VIDEOS_C4/C4_03_SRT_catalan.vtt' :
                                language === 'cs' ?
                                '/videos/VIDEOS_C4/C4_03_SRT_espanol.vtt' :
                                '/videos/VIDEOS_C4/C4_03_SRT_ingles.vtt'
                            }
                        />
                        <Image 
                            callback={changeWithClick} 
                            customClasses={`absolute-content  ${indicator===7 || indicator === 8 ? ` content-active` : `content-disabled`}`} 
                            uri="/images/CAP_04/C4_04.jpg" 
                        />
                    </>
                }{
                    (indicator >= 9 && indicator < 11) &&
                    <>
                        <Image 
                            callback={changeWithClick} 
                            customClasses={`absolute-content ${indicator===9 ? ` content-active` : ` content-disabled`}`} 
                            uri="/images/CAP_04/C4_05.jpg" 
                        />
                        <Video indicator={indicator}
                            callback={changeWithClick} 
                            showControls={true} 
                            index={10} 
                            customClasses={`absolute-content ${indicator=== 10 ? `content-active` : `content-disabled`}`} 
                            uri={"/videos/VIDEOS_C4/C4_06.mp4"}
                            subtitle={
                                language === 'vl' ?
                                '/videos/VIDEOS_C4/C4_06_SRT_catalan.vtt' :
                                language === 'cs' ?
                                '/videos/VIDEOS_C4/C4_06_SRT_espanol.vtt' :
                                '/videos/VIDEOS_C4/C4_06_SRT_ingles.vtt'
                            }
                        />                        
                    </>
                }{
                    indicator === 11 &&
                    <Video indicator={indicator}
                        callback={changeWithClick} 
                        showControls={true} 
                        index={11} 
                        text={MESSAGES_VIDEOS[11]}
                        customClasses={`absolute-content ${indicator=== 11 ? `content-active` : `content-disabled`}`} 
                        uri={"/videos/VIDEOS_C4/C4_07.mp4"}
                        subtitle={
                            language === 'vl' ?
                            '/videos/VIDEOS_C4/C4_07_SRT_catalan.vtt' :
                            language === 'cs' ?
                            '/videos/VIDEOS_C4/C4_07_SRT_espanol.vtt' :
                            '/videos/VIDEOS_C4/C4_07_SRT_ingles.vtt'
                        }
                    />
                }{
                    (indicator >= 12 && indicator < 15) &&
                    <>
                        <Image 
                            callback={changeWithClick} 
                            customClasses={`absolute-content ${indicator===12 || indicator===13 ? ` content-active` : ` content-disabled`}`} 
                            uri="/images/CAP_04/C4_08.jpg" 
                        />
                        <Video indicator={indicator}
                            callback={changeWithClick} 
                            showControls={true} 
                            index={14} 
                            customClasses={`absolute-content ${indicator=== 14 ? `content-active` : `content-disabled`}`} 
                            uri={"/videos/VIDEOS_C4/C4_09.mp4"}
                            subtitle={
                                language === 'vl' ?
                                '/videos/VIDEOS_C4/C4_09_SRT_catalan.vtt' :
                                language === 'cs' ?
                                '/videos/VIDEOS_C4/C4_09_SRT_espanol.vtt' :
                                '/videos/VIDEOS_C4/C4_09_SRT_ingles.vtt'
                            }
                        />
                    </>
                }{
                    indicator === 15 &&
                        <Video indicator={indicator}
                            callback={changeWithClick} 
                            showControls={true} 
                            index={15} 
                            customClasses={`absolute-content ${indicator=== 15 ? `content-active` : `content-disabled`}`} 
                            uri={"/videos/VIDEOS_C4/C4_10.mp4"}
                            subtitle={
                                language === 'vl' ?
                                '/videos/VIDEOS_C4/C4_10_SRT_catalan.vtt' :
                                language === 'cs' ?
                                '/videos/VIDEOS_C4/C4_10_SRT_espanol.vtt' :
                                '/videos/VIDEOS_C4/C4_10_SRT_ingles.vtt'
                            }
                        />
                }{
                    indicator === 16 &&
                        <Video indicator={indicator}
                            callback={changeWithClick} 
                            showControls={true} 
                            index={16} 
                            customClasses={`absolute-content ${indicator=== 16 ? `content-active` : `content-disabled`}`} 
                            uri={"/videos/VIDEOS_C4/C4_11.mp4"} 
                            subtitle={
                                language === 'vl' ?
                                '/videos/VIDEOS_C4/C4_11_SRT_catalan.vtt' :
                                language === 'cs' ?
                                '/videos/VIDEOS_C4/C4_11_SRT_espanol.vtt' :
                                '/videos/VIDEOS_C4/C4_11_SRT_ingles.vtt'
                            }
                        />
                }{
                    indicator === 17 &&
                    <Video indicator={indicator}
                            callback={changeWithClick} 
                            showControls={false} 
                            index={17} 
                            customClasses={`absolute-content ${indicator=== 17 ? `content-active` : `content-disabled`}`} 
                            uri={"/videos/VIDEOS_C4/C4_12.mp4"}
                        />
                }{
                    indicator === 18 &&
                        <Video indicator={indicator}
                            callback={changeWithClick} 
                            showControls={true} 
                            index={18} 
                            customClasses={`absolute-content ${indicator=== 18 ? `content-active` : `content-disabled`}`} 
                            uri={"/videos/VIDEOS_C4/C4_13.mp4"}
                            subtitle={
                                language === 'vl' ?
                                '/videos/VIDEOS_C4/C4_13_SRT_catalan.vtt' :
                                language === 'cs' ?
                                '/videos/VIDEOS_C4/C4_13_SRT_espanol.vtt' :
                                '/videos/VIDEOS_C4/C4_13_SRT_ingles.vtt'
                            }
                        />
                }{
                    (indicator >= 19 && indicator < 21) &&
                    <>
                        <Video indicator={indicator}
                            callback={changeWithClick} 
                            showControls={true} 
                            index={19} 
                            text={MESSAGES_VIDEOS[19]}
                            customClasses={`absolute-content ${indicator=== 19 ? `content-active` : `content-disabled`}`} 
                            uri={"/videos/VIDEOS_C4/C4_14.mp4"} 
                            subtitle={
                                language === 'vl' ?
                                '/videos/VIDEOS_C4/C4_14_SRT_catalan.vtt' :
                                language === 'cs' ?
                                '/videos/VIDEOS_C4/C4_14_SRT_espanol.vtt' :
                                '/videos/VIDEOS_C4/C4_14_SRT_ingles.vtt'
                            }
                        />
                        <Image 
                            callback={changeWithClick} 
                            customClasses={`absolute-content ${indicator===20 ? ` content-active` : ` content-disabled`}`} 
                            uri="/images/CAP_04/C4_15.jpg" 
                        />
                    </>
                }{
                    (indicator >= 21 && indicator < 26) &&
                    <>
                        {/* Annexed start */}
                        <Image 
                            callback={changeWithClick} 
                            customClasses={`absolute-content ${ indicator === 21 || indicator === 22 ||indicator===23 || indicator === 24 || indicator === 25 ? ` content-active` : ` content-disabled`}`} 
                            uri="/images/CAP_04/C4_15_GRAFICA_NUEVA.jpg" 
                        />               
                        {/* Annexed end */}
                    </>
                }{
                    indicator === 26 &&
                    <Video indicator={indicator}
                            callback={changeWithClick} 
                            showControls={true} 
                            index={26} 
                            customClasses={`absolute-content ${indicator=== 26 ? `content-active` : `content-disabled`}`} 
                            uri={"/videos/VIDEOS_C4/C4_16.mp4"} 
                            subtitle={
                                language === 'vl' ?
                                '/videos/VIDEOS_C4/C4_16_SRT_catalan.vtt' :
                                language === 'cs' ?
                                '/videos/VIDEOS_C4/C4_16_SRT_espanol.vtt' :
                                '/videos/VIDEOS_C4/C4_16_SRT_ingles.vtt'
                            }
                        />
                }{
                    (indicator >= 27 && indicator < 29) &&
                    <>                        
                        <Video indicator={indicator}
                            callback={changeWithClick} 
                            showControls={false} 
                            index={27} 
                            customClasses={`absolute-content ${indicator=== 27 ? `content-active` : `content-disabled`}`} 
                            uri={"/videos/VIDEOS_C4/C4_17.mp4"}
                        />
                        <Special 
                                customClasses={`absolute-content ${indicator===28 ? `content-active` : `content-disabled`}`} 
                                background="/images/CAP_04/C4_18.jpg"
                        >
                            <div>
                                <div className={styles.special_footer}>
                                    <Button onClick={() => handleIndicator.add(customDiapositives, TOTAL, 1)} dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'chapter.4.C4_18.button.1' }) }} />
                                    <Button onClick={changeWithClick}>{ intl.formatMessage({ id: 'chapter.4.C4_18.button.2' })}</Button>
                                </div>
                            </div>
                        </Special>                
                    </>
                }{
                    (indicator >= 29 && indicator < 35) &&
                    <>
                        {/* Annexed Star */}
                        <Image 
                            callback={changeWithClick} 
                            customClasses={`absolute-content ${indicator === 29 || indicator === 30 || indicator === 31 ? ` content-active` : ` content-disabled`}`} 
                            uri="/images/CAP_04/A_02_01.jpg" 
                        />
                        <Video indicator={indicator}
                            callback={changeWithClick} 
                            showControls={true} 
                            index={32} 
                            customClasses={`absolute-content ${indicator === 32 ? `content-active` : `content-disabled`}`} 
                            uri={"/videos/VIDEOS_C4/A_02_02.mp4"}
                            subtitle={
                                language === 'vl' ?
                                '/videos/VIDEOS_C4/A_02_02_SRT_catalan.vtt' :
                                language === 'cs' ?
                                '/videos/VIDEOS_C4/A_02_02_SRT_espanol.vtt' :
                                '/videos/VIDEOS_C4/A_02_02_SRT_ingles.vtt'
                            }
                        />
                        <Image 
                            callback={changeWithClick} 
                            customClasses={`absolute-content  ${indicator === 33 || indicator === 34 ? ` content-active` : `content-disabled`}`} 
                            uri="/images/CAP_04/A_02_03.jpg" 
                        />
                    </>
                }{
                    indicator === 35 &&
                        <Video indicator={indicator}
                            callback={changeWithClick} 
                            showControls={true} 
                            index={35} 
                            customClasses={`absolute-content ${indicator === 35 ? `content-active` : `content-disabled`}`} 
                            uri={"/videos/VIDEOS_C4/A_02_04.mp4"} 
                            subtitle={
                                language === 'vl' ?
                                '/videos/VIDEOS_C4/A_02_04_SRT_catalan.vtt' :
                                language === 'cs' ?
                                '/videos/VIDEOS_C4/A_02_04_SRT_espanol.vtt' :
                                '/videos/VIDEOS_C4/A_02_04_SRT_ingles.vtt'
                            }
                        />
                }{
                    indicator === 36 &&
                        <Video indicator={indicator}
                            callback={changeWithClick} 
                            showControls={true} 
                            index={36} 
                            customClasses={`absolute-content ${indicator === 36 ? `content-active` : `content-disabled`}`} 
                            uri={"/videos/VIDEOS_C4/A_02_05.mp4"}
                            subtitle={
                                language === 'vl' ?
                                '/videos/VIDEOS_C4/A_02_05_SRT_catalan.vtt' :
                                language === 'cs' ?
                                '/videos/VIDEOS_C4/A_02_05_SRT_espanol.vtt' :
                                '/videos/VIDEOS_C4/A_02_05_SRT_ingles.vtt'
                            }
                        />
                }{
                    (indicator >= 37 && indicator < 42) &&
                    <>
                        <Image 
                            callback={changeWithClick} 
                            customClasses={`absolute-content ${indicator === 37 || indicator === 38 ? ` content-active` : ` content-disabled`}`} 
                            uri="/images/CAP_04/A_02_06.jpg" 
                        />
                        <Video indicator={indicator}
                            callback={changeWithClick} 
                            showControls={true} 
                            index={39} 
                            customClasses={`absolute-content ${indicator === 39 ? `content-active` : `content-disabled`}`} 
                            uri={"/videos/VIDEOS_C4/A_02_07.mp4"} 
                            subtitle={
                                language === 'vl' ?
                                '/videos/VIDEOS_C4/A_02_07_SRT_catalan.vtt' :
                                language === 'cs' ?
                                '/videos/VIDEOS_C4/A_02_07_SRT_espanol.vtt' :
                                '/videos/VIDEOS_C4/A_02_07_SRT_ingles.vtt'
                            }
                        />
                        <Image 
                            callback={changeWithClick} 
                            customClasses={`absolute-content ${indicator === 40 || indicator === 41 ? `content-active` : ` content-disabled`}`} 
                            uri="/images/CAP_04/A_02_08.jpg" 
                        />
                    </>
                }{
                    (indicator >= 42 && indicator < 44) &&
                    <>
                        <Video indicator={indicator}
                            callback={changeWithClick} 
                            showControls={true} 
                            index={42} 
                            customClasses={`absolute-content ${indicator === 42 ? `content-active` : `content-disabled`}`} 
                            uri={"/videos/VIDEOS_C4/A_02_09.mp4"}
                            subtitle={
                                language === 'vl' ?
                                '/videos/VIDEOS_C4/A_02_09_SRT_catalan.vtt' :
                                language === 'cs' ?
                                '/videos/VIDEOS_C4/A_02_09_SRT_espanol.vtt' :
                                '/videos/VIDEOS_C4/A_02_09_SRT_ingles.vtt'
                            }
                        />
                        <Special 
                                customClasses={`absolute-content ${indicator===43 ? `content-active` : `content-disabled`}`} 
                                background="/images/CAP_04/A_02_10.jpg"
                        >
                            <div style={{zIndex: 50, marginTop: 100}}>
                                <div className={styles.special_footer}>
                                    <Button onClick={changeWithClick}>{ intl.formatMessage({ id: 'chapter.4.C4_18.button.2' })}</Button>
                                </div>
                            </div>
                        </Special>
                        {/* Annexed End */}
                    </>
                }{
                    indicator === 44 &&
                    <Video indicator={indicator}
                        callback={changeWithClick} 
                        showControls={true} 
                        index={44} 
                        text={MESSAGES_VIDEOS[44]}
                        customClasses={`absolute-content ${indicator=== 44 ? `content-active` : `content-disabled`}`} 
                        uri={"/videos/VIDEOS_C4/C4_19.mp4"}
                        subtitle={
                            language === 'vl' ?
                            '/videos/VIDEOS_C4/C4_19_SRT_catalan.vtt' :
                            language === 'cs' ?
                            '/videos/VIDEOS_C4/C4_19_SRT_espanol.vtt' :
                            '/videos/VIDEOS_C4/C4_19_SRT_ingles.vtt'
                        }
                    />
                }{
                    (indicator >= 45 && indicator < 49) &&
                    <>                        
                        <Video indicator={indicator}
                            callback={changeWithClick} 
                            showControls={true} 
                            index={45} 
                            customClasses={`absolute-content ${indicator=== 45 ? `content-active` : `content-disabled`}`} 
                            uri={"/videos/VIDEOS_C4/C4_20.mp4"}
                            subtitle={
                                language === 'vl' ?
                                '/videos/VIDEOS_C4/C4_20_SRT_catalan.vtt' :
                                language === 'cs' ?
                                '/videos/VIDEOS_C4/C4_20_SRT_espanol.vtt' :
                                '/videos/VIDEOS_C4/C4_20_SRT_ingles.vtt'
                            }
                        />
                        <Image 
                            callback={changeWithClick} 
                            customClasses={`absolute-content ${indicator===46 || indicator===47 ? ` content-active` : ` content-disabled`}`} 
                            uri="/images/CAP_04/C4_21.jpg" 
                        />
                        <Image 
                            callback={changeWithClick} 
                            customClasses={`absolute-content ${indicator===48 ? ` content-active` : ` content-disabled`}`} 
                            uri="/images/CAP_04/C4_22.jpg" 
                        />
                    </>
                }{
                    indicator === 49 &&
                    <Video indicator={indicator}
                        callback={changeWithClick} 
                        showControls={true} 
                        index={49} 
                        customClasses={`absolute-content ${indicator=== 49 ? `content-active` : `content-disabled`}`} 
                        uri={"/videos/VIDEOS_C4/C4_23.mp4"}
                        subtitle={
                            language === 'vl' ?
                            '/videos/VIDEOS_C4/C4_23_SRT_catalan.vtt' :
                            language === 'cs' ?
                            '/videos/VIDEOS_C4/C4_23_SRT_espanol.vtt' :
                            '/videos/VIDEOS_C4/C4_23_SRT_ingles.vtt'
                        }
                    />
                }{
                    indicator === 50 &&
                    <Video indicator={indicator}
                        callback={changeWithClick} 
                        showControls={true} 
                        index={50} 
                        customClasses={`absolute-content ${indicator=== 50 ? `content-active` : `content-disabled`}`} 
                        uri={"/videos/VIDEOS_C4/C4_24.mp4"}
                        subtitle={
                            language === 'vl' ?
                            '/videos/VIDEOS_C4/C4_24_SRT_catalan.vtt' :
                            language === 'cs' ?
                            '/videos/VIDEOS_C4/C4_24_SRT_espanol.vtt' :
                            '/videos/VIDEOS_C4/C4_24_SRT_ingles.vtt'
                        }
                    />
                }{
                    (indicator >= 51 && indicator < 53) &&
                    <>
                        <Video indicator={indicator}
                            callback={changeWithClick} 
                            showControls={true} 
                            index={51} 
                            text={MESSAGES_VIDEOS[51]}
                            customClasses={`absolute-content ${indicator=== 51 ? `content-active` : `content-disabled`}`} 
                            uri={"/videos/VIDEOS_C4/C4_25.mp4"}
                            subtitle={
                                language === 'vl' ?
                                '/videos/VIDEOS_C4/C4_25_SRT_catalan.vtt' :
                                language === 'cs' ?
                                '/videos/VIDEOS_C4/C4_25_SRT_espanol.vtt' :
                                '/videos/VIDEOS_C4/C4_25_SRT_ingles.vtt'
                            }
                        />
                        <Image 
                            callback={changeWithClick} 
                            customClasses={`absolute-content ${indicator===52 ? ` content-active` : ` content-disabled`}`} 
                            uri="/images/CAP_04/C4_26.jpg" 
                        />
                    </>
                }{
                    indicator === 53 &&
                        <Video indicator={indicator}
                            callback={changeWithClick} 
                            showControls={true} 
                            index={53} 
                            customClasses={`absolute-content ${indicator=== 53 ? `content-active` : `content-disabled`}`} 
                            uri={"/videos/VIDEOS_C4/C4_27.mp4"}
                            subtitle={
                                language === 'vl' ?
                                '/videos/VIDEOS_C4/C4_27_SRT_catalan.vtt' :
                                language === 'cs' ?
                                '/videos/VIDEOS_C4/C4_27_SRT_espanol.vtt' :
                                '/videos/VIDEOS_C4/C4_27_SRT_ingles.vtt'
                            }
                        />
                }{
                    indicator === 54 &&
                    <Video indicator={indicator}
                        callback={changeWithClick} 
                        showControls={true} 
                        index={54} 
                        text={MESSAGES_VIDEOS[54]}
                        customClasses={`absolute-content ${indicator=== 54 ? `content-active` : `content-disabled`}`} 
                        uri={"/videos/VIDEOS_C4/C4_27B.mp4"}
                        subtitle={
                            language === 'vl' ?
                            '/videos/VIDEOS_C4/C4_27B_SRT_catalan.vtt' :
                            language === 'cs' ?
                            '/videos/VIDEOS_C4/C4_27B_SRT_espanol.vtt' :
                            '/videos/VIDEOS_C4/C4_27B_SRT_ingles.vtt'
                        }
                    />
                }{
                    (indicator >= 55) &&
                    <>                        
                        <Video indicator={indicator}
                            callback={changeWithClick} 
                            showControls={true} 
                            index={55} 
                            customClasses={`absolute-content ${indicator=== 55 ? `content-active` : `content-disabled`}`} 
                            uri={"/videos/VIDEOS_C4/C4_27C.mp4"}
                            subtitle={
                                language === 'vl' ?
                                '/videos/VIDEOS_C4/C4_27C_SRT_catalan.vtt' :
                                language === 'cs' ?
                                '/videos/VIDEOS_C4/C4_27C_SRT_espanol.vtt' :
                                '/videos/VIDEOS_C4/C4_27C_SRT_ingles.vtt'
                            }
                        />
                        <Special customClasses={`absolute-content ${indicator===56 ? `content-active` : `content-disabled`}`} background="/images/CAP_04/C4_28.jpg">
                            <div>
                                <div>
                                    <Button onClick={()=>handleFinal("/chapters?show=true&chapter=5")}>{ intl.formatMessage({ id: 'button.2.final.chapter.1' })}</Button>                            
                                </div>
                            </div>
                        </Special>
                    </>
                }
                {/* Content End */}
                {
                    SPECIAL_TEXT_KNOW_MORE[indicator] && SPECIAL_TEXT_KNOW_MORE[indicator].map((item, index) => (
                        <SpecialText
                            text={intl.formatMessage({ id: item.text })}
                            style={{
                                top: `${((index + 1) * 12) + (index * 5)}vh`
                            }}
                        />
                    ))
                }{
                    SPECIAL_TEXT[indicator] &&
                    <SpecialText 
                        title={intl.formatMessage({ id: SPECIAL_TEXT[indicator].title })}
                        text={intl.formatMessage({ id: SPECIAL_TEXT[indicator].text })}
                    />
                }{
                    MESSAGES_ANNEXED[indicator] && MESSAGES_ANNEXED[indicator].map((text,i) =>(
                            <DynamicText
                                key={text}
                                customClasses={
                                    `${styles.dynamic_text}
                                    ${css.color_blue} 
                                    ${css.handleText3_4}
                                    `
                                }
                                style={{
                                    background: '#ff6b07',
                                    marginTop: `${i * 50}px`,
                                }}
                            >
                                { intl.formatMessage({ id: text}) }
                            </DynamicText>
                        ))                        
                }{
                    MESSAGES[indicator] && MESSAGES[indicator].map((text,i) =>(
                        <DynamicText
                            key={text}
                            customClasses={
                                `${css.color_blue} 
                                ${css.center_text}
                                ${ (indicator === 4 || indicator === 5) ? css.handleText3_4 : ''}
                                ${ indicator === 8 ? css.handleText8 : ''}
                                `
                            }
                            style={{
                                marginTop: `${i * 50}px`,
                            }}
                        >
                            { intl.formatMessage({ id: text}) }
                        </DynamicText>
                    ))
                }{
                    SENTENCE[indicator] &&
                    <Sentence 
                        text={intl.formatMessage({ id: SENTENCE[indicator].text })}
                        signature={SENTENCE[indicator].signature ? intl.formatMessage({ id: SENTENCE[indicator].signature })  : ''}
                    />
                }{
                    (indicator > 20 && indicator < 26) ?
                    <Indicator indicatorsArray={[21, 22, 23, 24, 25]} indicator={indicator} /> :
                    (indicator > 28 && indicator < 44) ?
                    <Indicator indicatorsArray={[
                        29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43
                    ]} indicator={indicator} /> :
                    <Indicator indicatorsArray={[
                        1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,
                        26,27,28,44,45,46,47,48,49,50,51,52,53,54,55, 56
                    ]} indicator={indicator} />
                }
                <Audio 
                    uri={
                        indicator < 9 ? "/audios/CAP_04/MUS01.mp3" :
                        (indicator >= 9 && indicator < 12) ? "/audios/CAP_04/AMB_01_MUNTA.mp3" :
                        (indicator >= 12 && indicator < 14) ? "/audios/CAP_04/MUS02.mp3" :
                        (indicator >= 14 && indicator < 16) ? "/audios/CAP_04/AMB_02_URBA.mp3" :
                        (indicator >= 16 && indicator < 18) ? "/audios/CAP_04/AMB_03_CAMP.mp3" :
                        (indicator >= 18 && indicator < 20) ? "" :
                        (indicator >= 20 && indicator < 26) ? "/audios/CAP_04/MUS03.mp3" :
                        (indicator >= 26 && indicator < 28) ? "/audios/CAP_04/AMB_04_CARRER.mp3" :
                        (indicator >= 28 && indicator < 32) ? "/audios/CAP_04/MUS_GUADALAVIAR.mp3" :
                        (indicator >= 32 && indicator < 35) ? "/audios/CAP_04/AMB_07_CARRER.mp3" :
                        (indicator >= 35 && indicator < 40) ? "/audios/CAP_04/AMB_08_POBLE.mp3" :
                        (indicator >= 40 && indicator < 42) ? "/audios/CAP_04/AMB_09_CASCADA.mp3" :
                        (indicator >= 42 && indicator < 44) ? "/audios/CAP_04/AMB_10_FAMILIA.mp3" :
                        indicator === 44 ? "" :
                        (indicator >=45 && indicator < 48) ? "/audios/CAP_04/MUS04.mp3" :
                        (indicator >= 48 && indicator < 52) ? "/audios/CAP_04/AMB_06_HURDES.mp3" :
                        indicator >=52 && "/audios/CAP_04/MUS05.mp3"
                    }
                    indicator={indicator}
                    elements={[
                        1, 3, 4, 5, 7, 8, 9, 12, 13, 17, 20, 21, 22, 23, 24, 25, 27, 28, 
                        29, 30, 31, 33, 34, 37, 38, 40, 41, 43, 46, 47, 48, 52, 56
                    ]}
                /> 
                {
                    showKnowMore &&
                    <KnowMore customClasses={styles.know_more} onClick={handleShowKnowMore} />
                }{/* Show Navigator */}
                {
                        indicator > 1 ?
                        <>{
                            showMessage && 
                            <Navigate 
                                indicator={indicator} 
                                handleClick={changeWithClick}
                            />
                        }</>
                        :   <InitialNavigation 
                                showArrow={showMessage}
                                handleClick={changeWithClick}
                            />
                }{/* Logo Fadrines */}
                <a className={styles.logo} href="/">
                    <img src="/images/logo.svg" alt="Logo" />
                </a>
            </div>
        </div>
    )
}

export default Animation4;