import React from 'react'
import styles from './Language.module.css'
import useAppContext from '../../context/Context'

export const Language = React.memo(({changeColor, ...otherProps}) => {
    const { language, setLanguage } = useAppContext()
    const [show, setShow] = React.useState(false)
    const LANGS = ['CS', 'VL', 'EN']
    const css = {
        backgroundColor: changeColor ? '#fff' : '',
        color: !changeColor ? '#fff' : 'rgb(40, 6, 208)',
    }

    const handleLanguageShow = () => {
        setShow(!show)
    }

    const handleLanguageChange = (data) => {
        setLanguage && setLanguage(data.toLowerCase())
        setShow(false)
    }

    return(
        <div className={`flex-row fixed ${styles.container}`} {...otherProps}> 
            <div className={`flex-row ${styles.body} ${show ? styles.inactive : styles.active}`}>
                <a className={`${styles.option} ${!show ? styles.active : styles.inactive}`} onClick={handleLanguageShow} style={css}>{language.toUpperCase()}</a>           
            </div>
            <div className={`${styles.body} ${show ? styles.active : styles.inactive}`}>{
                LANGS.map((lang, i) => (
                    <a className={styles.option} key={i} onClick={()=>handleLanguageChange(lang)} style={css}>{lang}</a>
                ))            
            }</div>
        </div>
    )
})