import React from 'react'
import { 
    Image, 
    Indicator, 
    DynamicText, 
    Video, 
    Button, 
    InitialNavigation,
    SpecialText, 
    Sentence, 
    Audio, 
    Navigate,
    Special
} from '../../components'
import styles from './animation.module.css'
import useAppContext from '../../context/Context'
import {useIntl} from 'react-intl'

import '../../styles/animation.css'


import { MESSAGES, MESSAGES_VIDEOS, SPECIAL_TEXT, SENTENCE, TOTAL } from '../../constants/chapter-1'

const Animation = () => {
    const { 
        indicator,
        language, 
        handleIndicator, 
        addClasses, 
        showMessage,
        handleNavigate,
        handleFinal,
        resetInit,
        pauseAllMedia,
        setPauseAllMedia
    } = useAppContext()
    const intl = useIntl()

    const customDiapositives = {
        refer: React.createRef(null),
        matchesIndicatorsAdd: [1, 8, 14],
        matchesIndicatorsRemove: [2, 9, 15]
    }

    const showNextDiapo = [1, 2, 3, 9, 10, 13, 15, 16, 20, 23, 28, 35]
    
    React.useEffect(resetInit, []);
    
    const handleScroll = (e) => {       
        e.deltaY > 20 && toggleIndicator("add");
        e.deltaY < -20 && toggleIndicator("remove");   
    }

    const pressFunction = (e) => {
        e.keyCode === 32 && setPauseAllMedia(!pauseAllMedia)
        e.keyCode === 40 && toggleIndicator("add");
        e.keyCode === 38 && toggleIndicator("remove"); 
    }

    React.useEffect(() => handleNavigate(showNextDiapo, 1),[indicator])

    React.useEffect(()=>{
        customDiapositives.refer.current.classList.add("first-animation")
    },[])

    const changeWithClick = () => {  
        toggleIndicator("add");
    }

    const toggleIndicator = (operation) => {
        let quantity = 1;
        switch (operation) {
            case "add": indicator === 21 && (quantity = 5);
            break;
            case "remove": indicator === 26 && (quantity = 5);
            break;
            default: {}
        }
        handleIndicator[operation](customDiapositives, TOTAL, quantity);
    }

    return(        
        <div className={'relative full-window-w full-window-h'} onWheel={handleScroll} onKeyDown={pressFunction} tabIndex={-1}>
            <div ref={customDiapositives.refer} className={`absolute full-w full-h ${styles.transition} ${addClasses ? styles.transitionActive : ''}`} />
            <div className={`relative full-window-w full-window-h grid ${styles.images}`}>
                {/* Content Start */}
                {
                    indicator < 4 &&
                    <>                        
                        <Image 
                            callback={changeWithClick} 
                            customClasses={`absolute-content ${indicator===1 ? ` content-active` : ` content-disabled`}`} 
                            uri="/images/C1_01.jpg" 
                        />
                        <Image 
                            callback={changeWithClick} 
                            customClasses={`absolute-content ${indicator===2 || indicator===3 ? ` content-active` : ` content-disabled`}`} 
                            uri="/images/bg-11.jpg" 
                        />
                    </>
                }{
                    indicator === 4 &&
                        <Video indicator={indicator}
                            callback={changeWithClick} 
                            showControls={true} 
                            index={4} 
                            customClasses={`absolute-content ${indicator===4 ? `content-active` : `content-disabled`}`} 
                            uri={"/videos/VIDEOS_C1/C1_03.mp4"}
                            subtitle={
                                language === 'vl' ?
                                '/videos/VIDEOS_C1/C1_03_SUB_catalan.vtt' :
                                language === 'cs' ?
                                '/videos/VIDEOS_C1/C1_03_SUB_espanol.vtt' :
                                '/videos/VIDEOS_C1/C1_03_SUB_ingles.vtt'
                            }
                        />
                }{
                    indicator === 5 &&
                    <Video indicator={indicator} 
                        callback={changeWithClick} 
                        showControls={true} 
                        index={5} 
                        customClasses={`absolute-content ${indicator===5 ? `content-active` : `content-disabled`}`} 
                        uri={"/videos/VIDEOS_C1/C1_04.mp4"}
                        subtitle={
                            language === 'vl' ?
                            '/videos/VIDEOS_C1/C1_04_SUB_catalan.vtt' :
                            language === 'cs' ?
                            '/videos/VIDEOS_C1/C1_04_SUB_espanol.vtt' :
                            '/videos/VIDEOS_C1/C1_04_SUB_ingles.vtt'
                        }
                    />
                }{
                    indicator === 6 &&
                    <Video indicator={indicator} 
                        callback={changeWithClick} 
                        showControls={true} 
                        index={6} 
                        customClasses={`absolute-content ${indicator===6 ? `content-active` : `content-disabled`}`} 
                        uri={"/videos/VIDEOS_C1/C1_05.mp4"}
                        subtitle={
                            language === 'vl' ?
                            '/videos/VIDEOS_C1/C1_05_SUB_catalan.vtt' :
                            language === 'cs' ?
                            '/videos/VIDEOS_C1/C1_05_SUB_espanol.vtt' :
                            '/videos/VIDEOS_C1/C1_05_SUB_ingles.vtt'
                        }
                    />                    
                }{
                    indicator === 7 &&
                    <Video indicator={indicator} 
                        callback={changeWithClick} 
                        showControls={true} 
                        index={7} 
                        customClasses={`absolute-content ${indicator===7 ? `content-active` : `content-disabled`}`} 
                        uri={"/videos/VIDEOS_C1/C1_07.mp4"}
                    />
                } {
                    (indicator >= 8 && indicator < 11) && 
                    <>
                        <Video indicator={indicator} 
                            callback={changeWithClick} 
                            showControls={true} 
                            index={8} 
                            customClasses={`absolute-content ${indicator===8 ? `content-active` : `content-disabled`}`} 
                            uri={"/videos/VIDEOS_C1/C1_08.mp4"} 
                            subtitle={
                                language === 'vl' ?
                                '/videos/VIDEOS_C1/C1_08_SUB_catalan.vtt' :
                                language === 'cs' ?
                                '/videos/VIDEOS_C1/C1_08_SUB_espanol.vtt' :
                                '/videos/VIDEOS_C1/C1_08_SUB_ingles.vtt'
                            }
                        /> 
                        <Image 
                            callback={changeWithClick} 
                            customClasses={`absolute-content ${indicator===9 || indicator === 10 ? ` content-active` : ` content-disabled`}`} 
                            uri="/images/bg-04.jpg" 
                        />
                    </>
                }{
                    indicator === 11 &&
                    <>
                        <Video indicator={indicator} 
                            callback={changeWithClick} 
                            showControls={true} 
                            index={11} 
                            customClasses={`absolute-content ${indicator===11 ? `content-active` : 'content-disabled'}`} 
                            uri={"/videos/VIDEOS_C1/C1_10.mp4"} 
                            subtitle={
                                language === 'vl' ?
                                '/videos/VIDEOS_C1/C1_10_SUB_catalan.vtt' :
                                language === 'cs' ?
                                '/videos/VIDEOS_C1/C1_10_SUB_espanol.vtt' :
                                '/videos/VIDEOS_C1/C1_10_SUB_ingles.vtt'
                            }
                        />                        
                    </> 
                }{
                    indicator === 12 &&
                    <Video indicator={indicator} 
                        callback={changeWithClick} 
                        showControls={true} 
                        index={12} 
                        customClasses={`absolute-content ${indicator===12 ? `content-active` : `content-disabled`}`} 
                        uri={"/videos/VIDEOS_C1/C1_11.mp4"} 
                        subtitle={
                            language === 'vl' ?
                            '/videos/VIDEOS_C1/C1_11_SUB_catalan.vtt' :
                            language === 'cs' ?
                            '/videos/VIDEOS_C1/C1_11_SUB_espanol.vtt' :
                            '/videos/VIDEOS_C1/C1_11_SUB_ingles.vtt'
                        }
                    /> 
                }                
                {
                    (indicator >= 13 && indicator < 17) &&
                    <>
                        <Image 
                            callback={changeWithClick} 
                            customClasses={`absolute-content ${indicator===13 ? ` content-active` : `content-disabled`}`} 
                            uri="/images/bg-08.jpg" 
                        />  
                        <Video indicator={indicator} 
                            callback={changeWithClick} 
                            showControls={true} 
                            index={14} 
                            text={MESSAGES[14]} 
                            customClasses={`absolute-content ${indicator===14 ? `content-active` : `content-disabled`}`} 
                            uri={"/videos/VIDEOS_C1/C1_13.mp4"} 
                            subtitle={
                                language === 'vl' ?
                                '/videos/VIDEOS_C1/C1_13_SUB_catalan.vtt' :
                                language === 'cs' ?
                                '/videos/VIDEOS_C1/C1_13_SUB_espanol.vtt' :
                                '/videos/VIDEOS_C1/C1_13_SUB_ingles.vtt'
                            }
                        />
                        <Image 
                            callback={changeWithClick} 
                            customClasses={`absolute-content ${indicator===15 || indicator === 16 ? ` content-active` : `content-disabled`}`} 
                            uri="/images/bg-15.jpg" 
                        /> 
                    </>
                }{
                    indicator === 17 &&
                    <Video indicator={indicator} 
                        callback={changeWithClick} 
                        showControls={true} 
                        text={MESSAGES_VIDEOS[17]} 
                        text1={MESSAGES_VIDEOS[17.5]} 
                        index={17} 
                        customClasses={`absolute-content ${indicator===17 ? `content-active` : `content-disabled`}`} 
                        uri={"/videos/VIDEOS_C1/C1_15.mp4"} 
                        subtitle={
                            language === 'vl' ?
                            '/videos/VIDEOS_C1/C1_15_SUB_catalan.vtt' :
                            language === 'cs' ?
                            '/videos/VIDEOS_C1/C1_15_SUB_espanol.vtt' :
                            '/videos/VIDEOS_C1/C1_15_SUB_ingles.vtt'
                        }
                    /> 
                }{
                    indicator === 18 &&
                    <Video indicator={indicator} 
                        callback={changeWithClick} 
                        showControls={false} 
                        index={18} 
                        customClasses={`absolute-content ${indicator===18 ? `content-active` : `content-disabled`}`} 
                        uri={"/videos/VIDEOS_C1/C1_17.mp4"}
                    />
                }{
                    indicator === 19 &&
                    <Video indicator={indicator} 
                        callback={changeWithClick} 
                        showControls={true} 
                        index={19} 
                        customClasses={`absolute-content ${indicator===19 ? `content-active` : `content-disabled`}`} 
                        uri={"/videos/VIDEOS_C1/C1_18.mp4"} 
                        subtitle={
                            language === 'vl' ?
                            '/videos/VIDEOS_C1/C1_18_SUB_catalan.vtt' :
                            language === 'cs' ?
                            '/videos/VIDEOS_C1/C1_18_SUB_espanol.vtt' :
                            '/videos/VIDEOS_C1/C1_18_SUB_ingles.vtt'
                        }
                    />   
                }{
                    (indicator >= 20 && indicator < 22) && 
                    <>
                        <Image 
                            callback={changeWithClick} 
                            customClasses={`absolute-content  ${indicator===20 ? `content-active` : `content-disabled`}`} 
                            uri="/images/bg-09.jpg" 
                        />    
                        <Special 
                            customClasses={`absolute-content ${indicator===21 ? `content-active` : `content-disabled`}`} 
                            background="/images/bg-13.jpg"
                        >
                            <div>                                
                                <div className={styles.special_footer}>
                                    <Button onClick={() => handleIndicator.add(customDiapositives, TOTAL)} dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'animation6.button1' }) }} />
                                    <Button onClick={changeWithClick}>{ intl.formatMessage({ id: 'animation6.button2' })}</Button>
                                </div>
                            </div>
                        </Special>
                    </>
                }{ 
                        
                    indicator === 22 &&
                        <Video indicator={indicator} 
                            callback={changeWithClick} 
                            showControls={true} 
                            index={22} 
                            customClasses={`absolute-content ${indicator === 22 ? `content-active` : `content-disabled`}`} 
                            uri={"/videos/VIDEOS_C1/A01_01.mp4"}
                            subtitle={
                                language === 'vl' ?
                                '/videos/VIDEOS_C1/A1_01_SUB_catalan.vtt' :
                                language === 'cs' ?
                                '/videos/VIDEOS_C1/A1_01_SUB_espanol.vtt' :
                                '/videos/VIDEOS_C1/A1_01_SUB_ingles.vtt'
                            }
                        />
                }{
                    (indicator >= 23 && indicator < 26) &&
                    <>
                        <Image 
                            callback={changeWithClick} 
                            customClasses={`absolute-content ${indicator=== 23 ? ` content-active` : ` content-disabled`}`} 
                            uri="/images/A01_02.jpg" 
                        />
                        <Video indicator={indicator} 
                            callback={changeWithClick} 
                            showControls={true} 
                            index={24} 
                            customClasses={`absolute-content ${indicator=== 24 ? `content-active` : `content-disabled`}`} 
                            uri={"/videos/VIDEOS_C1/A01_03.mp4"} 
                            subtitle={
                                language === 'vl' ?
                                '/videos/VIDEOS_C1/A1_03_SUB_catalan.vtt' :
                                language === 'cs' ?
                                '/videos/VIDEOS_C1/A1_03_SUB_espanol.vtt' :
                                '/videos/VIDEOS_C1/A1_03_SUB_ingles.vtt'
                            }
                        />                    
                        <Special 
                            customClasses={`absolute-content ${indicator=== 25 ? `content-active` : `content-disabled`}`} 
                        >
                            <Image 
                                callback={changeWithClick} 
                                customClasses={`${indicator === 25 ? `animate` : ''} ${`content-active`}`} 
                                uri="/images/A01_04.jpg" 
                            />
                            <div className='absolute' style={{zIndex: 50}}>
                                <div className={styles.special_footer}>
                                    <Button onClick={changeWithClick}>{ intl.formatMessage({ id: 'animation6.button2' })}</Button>
                                </div>
                            </div>
                        </Special>
                    </>
                }{
                    indicator === 26 &&
                    <Video indicator={indicator} 
                        callback={changeWithClick} 
                        showControls={true} 
                        index={26} 
                        customClasses={`absolute-content ${indicator===26 ? `content-active` : `content-disabled`}`} 
                        uri={"/videos/VIDEOS_C1/C1_21.mp4"} 
                    />
                }{
                    indicator === 27 &&
                    <Video indicator={indicator} 
                        callback={changeWithClick} 
                        showControls={true} 
                        index={27} 
                        customClasses={`absolute-content ${indicator===27 ? `content-active` : `content-disabled`}`} 
                        uri={"/videos/VIDEOS_C1/C1_22.mp4"} 
                        subtitle={
                            language === 'vl' ?
                            '/videos/VIDEOS_C1/C1_22_SUB_catalan.vtt' :
                            language === 'cs' ?
                            '/videos/VIDEOS_C1/C1_22_SUB_espanol.vtt' :
                            '/videos/VIDEOS_C1/C1_22_SUB_ingles.vtt'
                        }
                    />
                }{
                    indicator === 28 &&
                    <Video indicator={indicator} 
                        callback={changeWithClick} 
                        showControls={false} 
                        index={28} 
                        customClasses={`absolute-content ${indicator===28 ? `content-active` : `content-disabled`}`} 
                        uri={"/videos/VIDEOS_C1/C1_23.mp4"}
                    />                    
                }{
                    indicator === 29 &&
                    <Video indicator={indicator} 
                        callback={changeWithClick} 
                        showControls={true} 
                        index={29} 
                        customClasses={`absolute-content ${indicator===29 ? `content-active` : `content-disabled`}`} 
                        uri={"/videos/VIDEOS_C1/C1_24.mp4"} 
                        subtitle={
                            language === 'vl' ?
                            '/videos/VIDEOS_C1/C1_24_SUB_catalan.vtt' :
                            language === 'cs' ?
                            '/videos/VIDEOS_C1/C1_24_SUB_espanol.vtt' :
                            '/videos/VIDEOS_C1/C1_24_SUB_ingles.vtt'
                        }
                    />   
                }{
                    indicator === 30 && 
                    <Video indicator={indicator} 
                        callback={changeWithClick} 
                        showControls={true} 
                        index={30} 
                        text={MESSAGES_VIDEOS[30]} 
                        customClasses={`absolute-content ${indicator===30 ? `content-active` : `content-disabled`}`} 
                        uri={"/videos/VIDEOS_C1/C1_25.mp4"} 
                        subtitle={
                            language === 'vl' ?
                            '/videos/VIDEOS_C1/C1_25_SUB_catalan.vtt' :
                            language === 'cs' ?
                            '/videos/VIDEOS_C1/C1_25_SUB_espanol.vtt' :
                            '/videos/VIDEOS_C1/C1_25_SUB_ingles.vtt'
                        } 
                    />
                }{
                    indicator === 31 &&
                    <Video indicator={indicator} 
                        callback={changeWithClick} 
                        showControls={true} 
                        index={31} 
                        customClasses={`absolute-content ${indicator===31 ? `content-active` : `content-disabled`}`} 
                        uri={"/videos/VIDEOS_C1/C1_26.mp4"} 
                        subtitle={
                            language === 'vl' ?
                            '/videos/VIDEOS_C1/C1_26_SUB_catalan.vtt' :
                            language === 'cs' ?
                            '/videos/VIDEOS_C1/C1_26_SUB_espanol.vtt' :
                            '/videos/VIDEOS_C1/C1_26_SUB_ingles.vtt'
                        } 
                    />
                }{
                    indicator === 32 &&
                    <Video indicator={indicator} 
                        callback={changeWithClick} 
                        showControls={true} 
                        index={32} 
                        customClasses={`absolute-content ${indicator===32 ? `content-active` : `content-disabled`}`} 
                        uri={"/videos/VIDEOS_C1/C1_27.mp4"} 
                        subtitle={
                            language === 'vl' ?
                            '/videos/VIDEOS_C1/C1_27_SUB_catalan.vtt' :
                            language === 'cs' ?
                            '/videos/VIDEOS_C1/C1_27_SUB_espanol.vtt' :
                            '/videos/VIDEOS_C1/C1_27_SUB_ingles.vtt'
                        } 
                    />
                }{
                    indicator === 33 &&
                    <Video indicator={indicator} 
                        callback={changeWithClick} 
                        showControls={true} 
                        index={33} 
                        text={MESSAGES_VIDEOS[33]}
                        customClasses={`absolute-content ${indicator===33 ? `content-active` : `content-disabled`}`} 
                        uri={"/videos/VIDEOS_C1/C1_28.mp4"} 
                        subtitle={
                            language === 'vl' ?
                            '/videos/VIDEOS_C1/C1_28_SUB_catalan.vtt' :
                            language === 'cs' ?
                            '/videos/VIDEOS_C1/C1_28_SUB_espanol.vtt' :
                            '/videos/VIDEOS_C1/C1_28_SUB_ingles.vtt'
                        } 
                    />
                }{
                    indicator === 34 &&
                    <Video indicator={indicator} 
                        callback={changeWithClick} 
                        showControls={true} 
                        index={34} 
                        customClasses={`absolute-content ${indicator===34 ? `content-active` : `content-disabled`}`} 
                        uri={"/videos/VIDEOS_C1/C1_29.mp4"} 
                        subtitle={
                            language === 'vl' ?
                            '/videos/VIDEOS_C1/C1_29_SUB_catalan.vtt' :
                            language === 'cs' ?
                            '/videos/VIDEOS_C1/C1_29_SUB_espanol.vtt' :
                            '/videos/VIDEOS_C1/C1_29_SUB_ingles.vtt'
                        } 
                    />
                }{
                    indicator === 35 &&
                    <Video indicator={indicator} 
                        callback={changeWithClick} 
                        showControls={false} 
                        index={35} 
                        customClasses={`absolute-content ${indicator===35 ? `content-active` : `content-disabled`}`} 
                        uri={"/videos/VIDEOS_C1/C1_30.mp4"}
                    />                    
                }{
                    indicator === 36 &&
                    <Video indicator={indicator} 
                        callback={changeWithClick} 
                        showControls={true} 
                        index={36} 
                        customClasses={`absolute-content ${indicator===36 ? `content-active` : `content-disabled`}`} 
                        uri={"/videos/VIDEOS_C1/C1_31.mp4"}
                        subtitle={
                            language === 'vl' ?
                            '/videos/VIDEOS_C1/C1_31_SUB_catalan.vtt' :
                            language === 'cs' ?
                            '/videos/VIDEOS_C1/C1_31_SUB_espanol.vtt' :
                            '/videos/VIDEOS_C1/C1_31_SUB_ingles.vtt'
                        }
                    />                    
                }{
                    indicator === 37 &&
                    <>
                        <Special customClasses={`absolute-content ${indicator===37 ? `content-active` : `content-disabled`}`} background="/images/bg-14.jpg">
                            <div>
                                <div className={styles.special_footer}>
                                    <Button onClick={()=>handleFinal("/chapters?show=true&chapter=2")}>{ intl.formatMessage({ id: 'button.2.final.chapter.1' })}</Button>
                                </div>
                            </div>
                        </Special>
                    </>                    
                }                       
                {/* Content End */}
                {
                    (indicator > 21 && indicator < 26) ?
                    <Indicator indicatorsArray={[22, 23, 24, 25]} indicator={indicator} /> :
                    <Indicator indicatorsArray={[
                        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16,
                        17, 18, 19, 20, 21, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37
                    ]} indicator={indicator} />
                }
                {
                    indicator !== 14 &&
                    MESSAGES[indicator] && MESSAGES[indicator].map((text,i) =>(
                        <DynamicText
                            key={text}
                            customClasses={
                                `${styles.dynamic_text} 
                                ${indicator === 26 ? styles.special_dynamic_text_1 : ''}
                                ${indicator === 16 ? styles.special_dynamic_text_2 : ''}
                                `
                            }
                        >
                            { intl.formatMessage({ id: text}) }
                        </DynamicText>
                    ))
                }{
                    SPECIAL_TEXT[indicator] &&
                    <SpecialText 
                        title={intl.formatMessage({ id: SPECIAL_TEXT[indicator].title })}
                        text={intl.formatMessage({ id: SPECIAL_TEXT[indicator].text })}
                    />
                }{
                    (indicator === 23) &&
                    <SpecialText 
                        title={intl.formatMessage({ id: 'annexed.1.title' })}
                        text={intl.formatMessage({ id: 'annexed.1.description' })}
                        style={{ width: '35%'}}
                    />
                }{
                    SENTENCE[indicator] &&
                    <Sentence 
                        text={intl.formatMessage({ id: SENTENCE[indicator].text })}
                        signature={intl.formatMessage({ id: SENTENCE[indicator].signature })}
                    />
                }{
                    <Audio 
                        uri={
                            (indicator < 7) ? "/audios/C1_01_MUS_01.mp3" :
                            ((indicator >= 7) && (indicator < 11)) ? "/audios/C1_07_MUS_02.mp3" :
                            (indicator >= 11 && indicator < 13) ? "audios/C1_AMB_XIXARRES.mp3" :
                            ((indicator >= 13) && (indicator < 22)) ? "/audios/C1_12_MUS_03.mp3" :
                            (indicator >= 22 && indicator < 24) ? "/audios/AMB_DINAR.mp3" :
                            (indicator >= 24 && indicator < 26) ? "/audios/AMB_LLAVAOR.mp3" :
                            ((indicator >= 26) && (indicator < 29)) ? "/audios/C1_21_MUS_04.mp3" :
                            (indicator >= 29 && indicator < 31) ? "/audios/AMB_3-4.mp3" :
                            (indicator === 31) ? "/audios/C1_26_MUS_05.mp3" :
                            (indicator >= 34) ? "/audios/C1_29_MUS_06.mp3" : ''
                        }
                        indicator={indicator}
                        elements={[1, 2, 3, 9, 10, 13, 15, 16, 18, 20, 21, 23, 25, 28, 35, 37]}
                    /> 
                }{
                    indicator > 1 ?
                    <>{
                        showMessage && 
                        <Navigate 
                            indicator={indicator} 
                            handleClick={changeWithClick}
                        />
                    }</>
                    :   <InitialNavigation 
                            showArrow={showMessage}
                            handleClick={changeWithClick}
                        />
                }
                <a className={styles.logo} href="/">
                    <img src="/images/logo.svg" alt="Logo" />
                </a>
            </div>
        </div>
    )
}

export default Animation