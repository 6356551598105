import React from 'react'
import './Number.css'

export const Number = ({number, customClasses}) =>(
    <>    
        {
            number === 5 &&
            <svg className={customClasses} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 138.3 191.95"><defs></defs><g id="Capa_2" ><g id="Capa_1-2" ><path className="cls-1-numbers" d="M0,192v-7.12H21.49V7.12H0V0H134.34V38.63h-7.12V7.12H28.61V88.33h83.06v7.12H28.61v89.38H131.18v-31.5h7.12V192Z"/></g></g></svg>
        }{
            number === 4 &&
            <svg className={customClasses} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 148.2 213.2"><defs></defs><g id="Capa_2" ><g id="Capa_1-2" ><path className="cls-1-numbers" d="M79.5,213.2v-7.9h23.9V155.1H0v-4.4L101.8,0h9.5V147.2h36.9v7.9H111.3v50.2h23.9v7.9ZM12.3,147.1h91.1V11l-.9-.3L91.1,29.6Z"/></g></g></svg>
        }{
            number === 3 &&
            <svg className={customClasses} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 131.62 219.61"><defs></defs><g id="Capa_2" ><g id="Capa_1-2" ><path className="cls-1-numbers" d="M68,219.61a80.54,80.54,0,0,1-33.8-7.2,59.87,59.87,0,0,1-25.2-21c-6.3-9.2-9.3-20.3-9-33.4l.3-.9H7a50,50,0,0,0,8,28.1,54.91,54.91,0,0,0,21.8,19.3c9.2,4.7,19.6,7,31.2,7,17.6,0,31.2-4.5,41-13.6s14.6-21.6,14.7-37.6c0-16.6-5.6-28.9-16.8-36.8s-26.3-11.9-45.5-11.9H45.12v-8.2h16.3c11.9,0,22.2-2,30.8-6.2s15.2-9.6,19.8-16.5a40.71,40.71,0,0,0,7-23.4c0-14.4-4.4-26.2-13.3-35.5s-21.8-13.9-39-13.9a61.65,61.65,0,0,0-28.3,6.3A48.13,48.13,0,0,0,18.82,32c-4.8,7.7-7.2,16.6-7.2,26.9H4.82l-.3-.9c-.3-11.5,2.3-21.6,7.7-30.3a56.44,56.44,0,0,1,22.4-20.4,68.29,68.29,0,0,1,32-7.3c18.4,0,33,5.2,43.9,15.6s16.3,24.5,16.3,42.3a47.33,47.33,0,0,1-10.6,30.2c-7.1,8.9-16.6,15.3-28.6,19.2q20.85,5.1,32.4,18.6c7.7,9,11.6,20.3,11.6,34,0,12.4-2.7,23-8.1,31.9a52.8,52.8,0,0,1-22.4,20.5C91.52,217.21,80.52,219.61,68,219.61Z"/></g></g></svg>
        }{
            number === 2 &&
            <svg className={customClasses} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 132.04 216.4"><defs></defs><g id="Capa_2" ><g id="Capa_1-2" ><path className="cls-1-numbers" d="M4.44,216.4v-7.9l69.9-80.6a310.19,310.19,0,0,0,21.8-27.8,110.18,110.18,0,0,0,12.4-22.9,54.87,54.87,0,0,0,4-20q0-23.25-12.9-36.3c-8.6-8.7-21.1-13-37.7-13-17.5,0-31,5.1-40.6,15.4S7,46.8,7,63.1H.34L0,62.2a62.19,62.19,0,0,1,7.1-31.4A56.59,56.59,0,0,1,28.84,8.3C38.24,2.7,49.34,0,62,0c11.8,0,22.1,2.2,30.9,6.7a47.63,47.63,0,0,1,20.4,19.6c4.8,8.5,7.3,18.9,7.2,31a59.28,59.28,0,0,1-5.2,23.8,138.72,138.72,0,0,1-15,25.5c-6.5,9-14.5,19-23.7,29.8l-61.1,71.3.3.7h108.3v-35H132v42.9Z"/></g></g></svg>
        }{
            number === 1 &&
            <svg className={customClasses} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100.3 214.5"><defs></defs><g id="Capa_2" ><g id="Capa_1-2" ><path className="cls-1-numbers" d="M6.5,214.5v-7.9H49.4V8.6L0,19.6V12.3L57.4,0V206.5h42.9v7.9H6.5Z"/></g></g></svg>
        }{
            number === 0 &&
            <svg className={customClasses} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 129.93 219.43"><defs></defs><g id="Capa_2" ><g id="Capa_1-2" ><path className="cls-1-numbers" d="M65.19,219.43q-30.91,0-48-22.77T0,132.28V87.16Q0,45.55,17,22.78T64.89,0q30.92,0,48,22.78t17.06,64.38v45.12q0,41.75-16.92,64.45T65.19,219.43Zm0-7.91q27.68,0,42.26-21.24T122,132V87.16q0-37.21-14.65-58.23t-42.48-21q-27.82,0-42.4,21T7.91,87.16V132q0,36.92,14.72,58.23T65.19,211.52Z"/></g></g></svg>
        }
        </>
)