import React from "react";
import { 
    Image, 
    Indicator,  
    Video, 
    Button,
    Special,
    Plus,
    Audio,
    Navigate,
    Modal,
    InitialNavigation
} from '../../components'
import { useNavigate } from 'react-router-dom'
import {useIntl} from 'react-intl'
import useAppContext from '../../context/Context'
import styles from '../animation2/animation2.module.css'

const Animation5 = () =>{
    const { indicator, setIndicator, language, pauseAllMedia, setPauseAllMedia } = useAppContext()
    const intl = useIntl()
    const navigate = useNavigate()
    const [showCredits, setShowCredits] = React.useState(0);
    const [showMessage, setShowMessage] = React.useState(false)
    const [showModal, setShowModal] = React.useState(false)

    /**
     * Restart indicator
     */
     React.useState(() => {
        setIndicator && setIndicator(1)
    },[])

    const handleIndicator = {
        add: () => {
            setTimeout(()=>{
                indicator < 3 && setIndicator && setIndicator(indicator + 1)
            },300)
        },
        remove: () => {
            setTimeout(()=>{
                indicator > 1 && (setIndicator && setIndicator(indicator -1))           
            },300) 
        },
    }

    const handleScroll = (e) => {  
        if( !showModal ){
            e.deltaY > 20 && handleIndicator.add()
            e.deltaY < -20 && handleIndicator.remove()      
        }     
    }

    const pressFunction = (e) => { 
        if( !showModal ){
            e.keyCode === 32 && setPauseAllMedia(!pauseAllMedia)
            e.keyCode === 40 && handleIndicator.add() 
            e.keyCode === 38 && handleIndicator.remove() 
        }
    }

    const changeWithClick = () => {  
        handleIndicator.add() 
    }

    const handleFinal = (redirect) => {
        navigate(redirect)
    }

    const handleShowCredits = () => {
        if( showCredits === 1) handleStart()
        else setShowCredits( showCredits + 1)        
    }

    const handleNavigate = () => {
        setShowMessage(false)
        let timer = setTimeout(() =>{
            if( 
                (indicator === 1)
            ){
                setShowMessage(true)
                return
            }else{
                setShowMessage(false)
                clearTimeout(timer)
                return
            }
        },10000)
        return () => {
            clearInterval(timer);
        };
    }
    React.useEffect(handleNavigate,[indicator])

    const handleStart = () => {
       setTimeout(()=>{
            navigate("/");
       },2000)
    }

    const handleClickToStart = () => {
        navigate("/");
     }

    return(
        <div className={'relative full-window-w full-window-h'} onWheel={handleScroll} onKeyDown={pressFunction} tabIndex={-1}>
            <div className={`relative full-window-w full-window-h grid ${styles.images}`}>
                {/* Content Start */}
                <Image 
                    callback={changeWithClick} 
                    customClasses={`absolute-content ${indicator===1 ? ` content-active` : ` content-disabled`}`} 
                    uri="/images/CAP_05/C5_00.jpg" 
                />
               <Video indicator={indicator}
                    callback={changeWithClick} 
                    showControls={true} 
                    index={2}
                    customClasses={`absolute-content ${indicator=== 2 ? `content-active` : `content-disabled`}`} 
                    uri={"/videos/VIDEOS_C5/C5_02.mp4"} 
                    subtitle={
                        language === 'vl' ?
                        '/videos/VIDEOS_C5/C5_02_SRT_catalan.vtt' :
                        language === 'cs' ?
                        '/videos/VIDEOS_C5/C5_02_SRT_espanol.vtt' :
                        '/videos/VIDEOS_C5/C5_02_SRT_ingles.vtt'
                    }
                />
                <Special customClasses={`absolute-content ${indicator===3 ? `content-active` : `content-disabled`}`} background="/images/CAP_05/CAP_05.jpg">
                        <div className={styles.special_footer}>
                            <Button onClick={()=>handleFinal("/chapters?show=true")}>{ intl.formatMessage({ id: 'button.1.final.chapter.1' })}</Button>
                            <Button customClasses={styles.button_modal} onClick={handleShowCredits}>
                                <div><Plus /></div>
                                {intl.formatMessage({ id: 'credits'})}
                            </Button>
                            <Button customClasses={styles.button_modal} onClick={() => setShowModal(true)}>
                                <div><Plus /></div>
                                {intl.formatMessage({ id: 'legal.policy'})}
                            </Button>
                        </div>
                </Special>
                <Video indicator={showCredits}
                    callback={handleShowCredits} 
                    showControls={true} 
                    index={1}
                    credits={true}
                    customClasses={`absolute-content ${ showCredits === 1 ? `content-active` : `content-disabled`}`} 
                    uri={"/videos/VIDEOS_C5/CREDITS.mp4"} 
                />
                {
                    showCredits > 0 &&
                    <div className={styles.skip_video}>
                        <Button onClick={handleClickToStart}>{ intl.formatMessage({ id: 'credits.skip' })}</Button>
                    </div>
                }
                {
                    showCredits === 0 && 
                    <Audio 
                        uri={
                            indicator === 1 ? "/audios/CAP_05/C5_MUS1.mp3" :
                            indicator === 2 ? "" :
                            indicator === 3 && "/audios/CAP_05/C5_MUS1.mp3"
                        }
                        indicator={indicator}
                        elements={[1, 3]}
                    /> 
                }
                {/* Modal for Legal Policy */}
                {
                    showModal &&
                    <Modal 
                        callback={() => setShowModal(false)}
                        title={intl.formatMessage({ id: 'legal.policy'})}
                        text={intl.formatMessage({ id: 'legal.policy.text' })}
                    />
                }
                {/* Content End */}
                <Indicator indicatorsArray={[1, 2, 3]} indicator={indicator} />
                {
                        indicator > 1 ?
                        <>{
                            showMessage && 
                            <Navigate 
                                indicator={indicator} 
                                handleClick={changeWithClick}
                            />
                        }</>
                        :   <InitialNavigation 
                                showArrow={showMessage}
                                handleClick={changeWithClick}
                            />
                }
                {/* Logo Fadrines */}
                <a className={styles.logo} href="/">
                    <img src="/images/logo.svg" alt="Logo" />
                </a>
            </div>
        </div>
    )
}

export default Animation5