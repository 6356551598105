import React from 'react'
import styles from './Volume.module.css'

export const Volume = ({volume}) =>{
    return <>
    {volume ?
    <svg className={`cursor-pointer ${styles.volume}`} width="60px" height="60px" viewBox="0 0 60 60" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <title>icono-sonido-blanco</title>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Artboard" transform="translate(-272.000000, -255.000000)" fill="#FFFFFF" fillRule="nonzero">
                <g id="Group-2" transform="translate(272.000000, 255.000000)">
                    <path d="M31.075,37.5310113 L31.075,22 L23.663,26.2775057 L19,26.2780057 L19,33.2530057 L23.663,33.2525057 L31.075,37.5310113 Z M29.575,24.5975057 L29.575,34.9325057 L24.0669816,31.7530057 L20.5,31.7525057 L20.5,27.7775057 L24.0669816,27.7780057 L29.575,24.5975057 Z M34.7648014,25.8664774 C35.3332194,26.9587316 35.673365,28.1615371 35.7578047,29.4153067 C35.861669,30.9574445 35.5728476,32.4691434 34.9327593,33.8353156 L34.7788299,34.1479504 L33.4461701,33.459461 C34.0686042,32.2546595 34.3545305,30.9019101 34.2611952,29.516103 C34.1985668,28.5861908 33.9678326,27.6915636 33.5849011,26.8654501 L33.4341986,26.5589339 L34.7648014,25.8664774 Z M40.1179615,22.318179 C41.0288242,24.2688101 41.5760731,26.3792131 41.7237005,28.5700442 C41.9162148,31.424866 41.4189504,34.2277791 40.2978817,36.7895989 L40.1052723,37.2143041 L38.7475277,36.5767072 C39.8963093,34.1304093 40.4130722,31.4287833 40.227097,28.6709294 C40.1023766,26.8200446 39.6664577,25.0335952 38.9451076,23.3669623 L38.7588385,22.9528323 L40.1179615,22.318179 Z M37.4705307,24.2156179 C38.2178164,25.7047584 38.6649535,27.3317136 38.7784899,29.025387 C38.9270073,31.2209037 38.5063648,33.3726134 37.578016,35.3121149 L37.3980159,35.6732435 L36.0663841,34.9827678 C36.9943925,33.1930374 37.4210387,31.183347 37.2818795,29.1261698 C37.1908509,27.7682565 36.8572143,26.4609488 36.3027963,25.2490545 L36.1298693,24.8883934 L37.4705307,24.2156179 Z" id="icono-sonido-blanco"></path>
                </g>
            </g>
        </g>
    </svg>
    :   <svg className={`cursor-pointer ${styles.volume}`} width="60px" height="60px" viewBox="0 0 60 60" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <title>icono-silencio-blanco</title>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Artboard" transform="translate(-272.000000, -333.000000)" fill="#FFFFFF" fillRule="nonzero">
                    <g id="Group-4" transform="translate(272.000000, 333.000000)">
                        <path d="M36.075,37.5310113 L36.075,22 L28.663,26.2775057 L24,26.2780057 L24,33.2530057 L28.663,33.2525057 L36.075,37.5310113 Z M34.575,24.5975057 L34.575,34.9325057 L29.0669816,31.7530057 L25.5,31.7525057 L25.5,27.7775057 L29.0669816,27.7780057 L34.575,24.5975057 Z" id="icono-silencio-blanco"></path>
                    </g>
                </g>
            </g>
        </svg>
    }
    </>
}