import React from 'react'
import './Instagram.css'

export const Instagram = ({type = 'blue'}) =>(
    <svg 
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 13.99 14.01"
        width="15px" height="15px"
    >
        <defs></defs>
        <g id="Capa_2">
            <g id="Capa_1-2">
                <path className={`cls-1-${type}`} d="M9.11,14H4.89l-.25,0A8.42,8.42,0,0,1,3,13.82a3.61,3.61,0,0,1-2.54-2A5.16,5.16,0,0,1,0,9.66c0-1.51,0-3,0-4.53A9.24,9.24,0,0,1,.19,3,3.56,3.56,0,0,1,2.41.36,5.41,5.41,0,0,1,4.4,0L8.67,0a10.52,10.52,0,0,1,2.24.16,3.59,3.59,0,0,1,2.79,2.4,5.64,5.64,0,0,1,.28,2c0,1.62,0,3.23,0,4.85a8.94,8.94,0,0,1-.11,1.37,3.66,3.66,0,0,1-1.3,2.33,4.15,4.15,0,0,1-2.46.88ZM7,12.77v0H8.1c.8,0,1.6,0,2.39-.12a2.31,2.31,0,0,0,2.08-1.83,6.34,6.34,0,0,0,.15-1.31c0-1.42,0-2.85,0-4.27a13.57,13.57,0,0,0-.1-1.69,2.34,2.34,0,0,0-2-2.11,8,8,0,0,0-1.47-.13c-1.23,0-2.45,0-3.68,0a13.38,13.38,0,0,0-2.1.12,2.36,2.36,0,0,0-2,1.92,8.19,8.19,0,0,0-.13,1.4c0,1.12,0,2.24,0,3.37,0,.79,0,1.58.1,2.37a2.34,2.34,0,0,0,1.12,1.83A3,3,0,0,0,4,12.69C5,12.73,6,12.74,7,12.77Z"/>
                <path className={`cls-1-${type}`} d="M3.4,7A3.6,3.6,0,1,1,7,10.6,3.61,3.61,0,0,1,3.4,7ZM4.67,7A2.33,2.33,0,1,0,7,4.67,2.33,2.33,0,0,0,4.67,7Z"/><path className="cls-1" d="M11.58,3.25a.83.83,0,0,1-.82.85.85.85,0,0,1-.86-.83.85.85,0,0,1,.83-.85A.83.83,0,0,1,11.58,3.25Z"/>
            </g>
        </g>
    </svg>
)