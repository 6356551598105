import React from 'react'
import { Language, Social } from '../../components'
import styles from './static.module.css'
import { useLocation } from 'react-router-dom'

const Static = React.memo(() => {
    const location = useLocation()
    return(
        <div className={styles.container}>
            {
                location.pathname !== "/" &&
                <Language 
                    changeColor={
                        location.pathname === '/chapters'
                        ? false
                        : true
                    }
                    style={{
                        bottom: location.pathname !== '/' && location.pathname !== '/chapters' ? 100 : '',
                    }}
                />
            }
            {
                (location.pathname === "/" || location.pathname === "/chapters") &&
                <Social />
            }
        </div>
    )
})

export default Static